import React from "react";

import { Inbox } from "supercell-frontend-base/src/components/inbox";

import styles from "./styles.modules.scss";

export const AppView = (props) => (
    <div className={`app ${styles.app}`}>
        <Inbox {...props} />
    </div>
);
