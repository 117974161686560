import React from "react";

import { VoteModalController } from "./vote-modal-controller";
import { withStyle } from "../../hocs/with-style";

export type VoteOption = {
    id: string;
    title: string;
    image: ImageSourceSets;
};

export type VoteModalProps = {
    pollId: string;
    title: string;
    pollTitle: string;
    options: VoteOption[];
    isOpen: boolean;
    onClose: () => void;
};

export type VoteModalType = (props: VoteModalProps) => JSX.Element | null;

export const VoteModal: VoteModalType = withStyle(VoteModalController);

(VoteModal as React.FC)["displayName"] = "VoteModal";
