import React, { useEffect, useMemo, useContext } from "react";
import { useLocation, useParams, Route, Routes } from "react-router-dom";

import { EsportContext, EsportContextProvider, EsportState } from "./store";
import { TRACK_EVENT } from "supercell-frontend-base/src/constants/types";
import { useSendCommand } from "supercell-frontend-base/src/hooks/use-send-command";

import { filterLiveArticles } from "supercell-frontend-base/src/utils/targeting/filter-live-articles";
import { addRegionToArticleCategories } from "./util";

import { calculateEsportInitialState } from "./region-utils";

import { EsportView } from "./view";

import { EsportArticle } from "./esport-article";

export const EsportController = () => {
    const loc = useLocation();
    const params = useParams();
    const { pathname: pagePath } = loc;
    const sendCommand = useSendCommand();

    useEffect(() => {
        sendCommand(TRACK_EVENT, {
            event: "Pageview",
            pagePath,
            pageTitle: "esport",
        });

        window.history.pushState({ home: true }, "");
    }, []);

    const initialEsportState: EsportState = useMemo(() => {
        const state = calculateEsportInitialState(
            undefined,
            params.lang ?? "en"
        );

        return state;
    }, [params.lang]);

    const EsportContent = () => {
        const { state } = useContext(EsportContext);

        const filterArticles = (articles) => {
            return filterLiveArticles(articles).map((a) =>
                addRegionToArticleCategories(a, state.selectedRegions)
            );
        };

        return (
            <Routes>
                <Route path="/:id/:slug/:variant" element={<EsportArticle />} />

                <Route path="/:id/:slug" element={<EsportArticle />} />

                <Route path="/:id" element={<EsportArticle />} />

                <Route
                    path="/"
                    element={<EsportView filterArticles={filterArticles} />}
                />
            </Routes>
        );
    };

    return (
        <EsportContextProvider initialState={initialEsportState}>
            <EsportContent />
        </EsportContextProvider>
    );
};
