import React from "react";
import { LinkType } from "./link/type";

import styles from "./styles.modules.scss";

type NavigationType = {
    Link: (props: LinkType) => JSX.Element;
    prevLinkProps?: LinkType;
    nextLinkProps?: LinkType;
};

export const NavigationView = ({
    Link,
    prevLinkProps,
    nextLinkProps,
}: NavigationType) => {
    return prevLinkProps || nextLinkProps ? (
        <div className={`navigation ${styles.navigation}`}>
            {prevLinkProps && (
                <div className={`prev ${styles.prev}`}>
                    <Link
                        {...prevLinkProps}
                        disabled={prevLinkProps.disabled}
                    />
                </div>
            )}

            {nextLinkProps && (
                <div className={`next ${styles.next}`}>
                    <Link
                        {...nextLinkProps}
                        next
                        disabled={nextLinkProps.disabled}
                    />
                </div>
            )}
        </div>
    ) : null;
};
