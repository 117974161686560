import React from "react";

import { ErrorBoundaryView } from "./view";

type propsType = {
    error?: boolean | Error;
    children: JSX.Element;
    locale: Locale;
};

type stateType = { error: boolean | Error };

export class ErrorBoundaryController extends React.Component<
    propsType,
    stateType
> {
    displayName = "ErrorBoundary";

    constructor(props: propsType) {
        super(props);

        this.state = { error: false };
    }

    static getDerivedStateFromError = (error: boolean) => ({ error });

    render() {
        const error = this.props.error || this.state.error;
        const locale = this.props.locale;

        if (error) {
            console.error(error);

            return <ErrorBoundaryView locale={locale} />;
        }

        return this.props.children;
    }
}
