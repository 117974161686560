import React from "react";

import { Hero } from "./hero";
import { Block } from "./block";
import { Navigation } from "./navigation";

import styles from "./styles.modules.scss";

type ArticleType = {
    article: InboxArticle;
    Link: (props: unknown) => JSX.Element;
    prevLinkProps?: Record<string, unknown>;
    nextLinkProps?: Record<string, unknown>;
    showHomeCta?: boolean;
    style: { direction: string };
};

export const ArticleView = ({
    article,
    Link,
    prevLinkProps,
    nextLinkProps,
    style: { direction },
    showHomeCta,
}: ArticleType) => {
    React.useEffect(() => {
        document.querySelector(".app")?.scrollTo(0, 0);
    }, [article?.id]);

    if (!article) return null;

    const {
        detailsTitle,
        title,
        heroImage,
        heroSurTitle,
        categories,
        postDate,
        hideTitle,
        details,
    } = article;

    return (
        <div
            className={`
                article ${styles.article}
                ${direction} ${direction === "rtl" ? styles.rtl : ""}
            `}
        >
            <div className="header">
                <Hero
                    categories={categories}
                    date={postDate}
                    image={heroImage}
                    hideTitle={hideTitle}
                    showHomeCta={showHomeCta}
                    surTitle={heroSurTitle}
                    title={title}
                />
            </div>

            <div className={`body ${styles.body}`}>
                {detailsTitle && (
                    <div className="body-title">{detailsTitle}</div>
                )}

                <div className={`details ${styles.details}`}>
                    {(details || []).map((detail, index) => (
                        <Block key={index} {...detail} />
                    ))}
                </div>
            </div>

            <div className={`footer ${styles.footer}`}>
                <Navigation
                    Link={Link}
                    prevLinkProps={prevLinkProps}
                    nextLinkProps={nextLinkProps}
                />
            </div>
        </div>
    );
};
