import React from "react";

import { VoteSlideView } from "./view";
import { FormattedMessage } from "react-intl";
import { VoteModal, VoteOption } from "../../../vote-modal";
import { usePollContext } from "../../../../stores/poll";

type VoteSlideControllerType = (props: {
    id: string;
    background: ImageSourceSets;
    hideTitle: boolean;
    title: string;
    pollTitle: string;
    options: VoteOption[];
}) => JSX.Element;

export const VoteSlideController: VoteSlideControllerType = ({
    id,
    background,
    hideTitle,
    title,
    pollTitle,
    options,
}) => {
    const {
        state,
        canVote,
        actions: { openModal, closeModal, getVote, refreshPollInfo },
    } = usePollContext();

    React.useEffect(() => {
        Promise.all([getVote(id), refreshPollInfo(id)]);
    }, [id]);

    const voteCta = {
        className: "vote-button",
        label: <FormattedMessage id="globalText.langVoteDefaultButtonLabel" />,
        onClick: () => openModal(id),
    };

    const showResultsCta = {
        className: "show-results-button",
        label: <FormattedMessage id="globalText.langVoteResultsButtonLabel" />,
        onClick: () => openModal(id),
    };

    // This is a little bit hairy. We want to show vote button when
    // - normal case: user can vote and the poll is open
    // - weird edge case, when nothing good is available: results are hidden, so we can't say "results", but user can't
    //   vote either, so neither is good, let's use "vote" which is less wrong
    const useVoteCta =
        (canVote(id) && state.polls[id]?.status !== "closed") ||
        state.polls[id]?.status === "open-hidden";

    const cta = useVoteCta ? voteCta : showResultsCta;

    const slideProps = {
        background,
        cta,
        hideTitle,
        title,
    };

    const modalProps = {
        pollId: id,
        title,
        pollTitle,
        isOpen: id === state.activePollId,
        onClose: () => closeModal(),
        options,
    };

    return (
        <React.Fragment>
            <VoteSlideView {...slideProps} />

            <VoteModal {...modalProps} />
        </React.Fragment>
    );
};
