type ChannelUrl = {
    [key in number]: {
        channelTarget: { title: string }[];
        channelURL: string;
    };
};

export const parseChannelUrlTargeting = (
    targets: ChannelUrl
): { [key in string]: string } =>
    Object.values(targets).reduce<Record<string, string>>(
        (prev, { channelTarget, channelURL }) => ({
            ...(prev as { [key: string]: string }),
            [channelTarget[0].title]: channelURL,
        }),
        {}
    );
