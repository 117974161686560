import React from "react";

import { withContentful } from "with-contentful";
import { withLoadedData } from "./with-loaded-data";

export const withData = (Component) => (props) => {
    const withDataProvider = window.CONTENTFUL_CONFIG
        ? withContentful
        : withLoadedData;
    const ComponentWithData = withDataProvider(Component);
    return <ComponentWithData {...props} />;
};
