import React from "react";

const defaultOptions = {
    threshold: 0.5,
};

export const useElementOnScreen = <RefType extends HTMLElement>(
    options?: IntersectionObserverInit
) => {
    const ref = React.useRef<RefType>(null);
    const [isVisible, setIsVisible] = React.useState(false);

    const callbackFunction = (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries;
        if (entry) {
            setIsVisible(entry.isIntersecting);
        }
    };

    React.useEffect(() => {
        if (!("IntersectionObserver" in window)) {
            setIsVisible(true);
            return;
        }

        const observer = new IntersectionObserver(callbackFunction, {
            ...defaultOptions,
            ...options,
        });
        if (ref.current) observer.observe(ref.current);

        return () => {
            if (ref.current) observer.unobserve(ref.current);
        };
    }, [ref, options]);

    return [ref, isVisible] as const;
};

export const useElementAppearsOnScreen = <RefType extends HTMLElement>(
    options?: IntersectionObserverInit
) => {
    const ref = React.useRef<RefType>(null);
    const [isVisible, setIsVisible] = React.useState(false);

    const callbackFunction = (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries;
        if (entry) {
            setIsVisible(isVisible || entry.isIntersecting);
        }
    };

    React.useEffect(() => {
        if (!("IntersectionObserver" in window)) {
            setIsVisible(true);
            return;
        }

        if (isVisible) {
            return;
        }

        const observer = new IntersectionObserver(callbackFunction, {
            ...defaultOptions,
            ...options,
        });
        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [isVisible, ref, options]);

    return [ref, isVisible] as const;
};
