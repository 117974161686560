import React from "react";

import { TYPE_EVENT_POLL } from "../../../constants/types";

import { DefaultSlide } from "./default";
import { VoteSlide } from "./vote";

export const CarouselSlideController = (props) => {
    const { type } = props;

    switch (type) {
        case TYPE_EVENT_POLL:
            return <VoteSlide {...props} />;
        default:
            return <DefaultSlide {...props} />;
    }
};
