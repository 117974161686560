import React from "react";
import { useNavigate } from "react-router-dom";

import { Provider as CommanderProvider } from "../../contexts/commander";

import { queueCommander } from "./queue-commander";

export const withCommanderContextProvider =
    (Component: typeof React.Component) => (props: Record<string, unknown>) => {
        const { commander } = props;

        const nav = useNavigate();
        const navigate = (path: string) => nav(path);
        const queuedCommander = queueCommander(commander(navigate));

        return (
            <CommanderProvider value={queuedCommander}>
                <Component {...props} />
            </CommanderProvider>
        );
    };
