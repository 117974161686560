import React from "react";

import { regionLabels } from "../../util";
import { EsportRegion } from "../../types";
import slugify from "slugify";
import styles from "./styles.modules.scss";

const RegionSelectButton = (props: {
    disabled: boolean;
    region: EsportRegion;
    onClick: (region: EsportRegion) => void;
}) => {
    const { disabled, region, onClick } = props;

    const regionSlug = slugify(region, { lower: true });

    const id = `${regionSlug}-btn`;

    return (
        <span id={id}>
            <div
                className={`region-select-btn ${styles["region-select-btn"]} ${
                    disabled
                        ? ""
                        : `region-select-btn-selected ${styles["region-select-btn-selected"]}`
                }`}
                onClick={() => onClick(region)}
            >
                <span
                    className={`${styles["checkmark-wrapper"]} checkmark-wrapper`}
                >
                    <span className={`${styles.checkmark} checkmark`} />
                </span>

                <span className={styles.region}>{regionLabels[region]}</span>
            </div>
        </span>
    );
};

export { RegionSelectButton };
