export const getAvatarId = (jwt: string): number | undefined => {
    try {
        const jwtParts = jwt.split(".");

        if (jwtParts.length !== 3) {
            return undefined;
        }

        const payload = JSON.parse(atob(jwtParts[1] ?? ""));

        if (!("avatarId" in payload)) {
            return undefined;
        }

        return payload.avatarId;
    } catch (error) {
        return undefined;
    }
};
