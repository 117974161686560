import { parseCTALinkType } from "./../type";

export const parseCTA = ({
    order,
    label,
    type,
    article,
    url,
    variant,
}: any): InboxCTA => ({
    order,
    label,
    type: parseCTALinkType(type),
    article: article && article[0],
    url,
    variant,
});
