import { useGameParamsContext } from "./gameParams";
import React from "react";
import { z } from "zod";
import { authenticate } from "../clients/session";

const LOCALSTORAGE_KEY = "session-cache";
const REFRESH_TOKEN_LIMIT = 1000 * 60 * 60 * 24; // Refresh session if it expires in less than a day

const SessionState = z.object({
    sessionToken: z.string().nullable(),
    expires: z.coerce.date(),
});
type SessionState = z.infer<typeof SessionState>;

const defaultState = {
    sessionToken: null,
    expires: new Date(0),
};

const SessionContext = React.createContext<{
    state: SessionState;
    actions: {
        refreshSession: () => void;
    };
}>({
    state: defaultState,
    actions: {
        refreshSession: () => null,
    },
});

type SessionAction = { type: "UPDATE_SESSION"; session: SessionState };
const reducer = (state: SessionState, action: SessionAction): SessionState => {
    switch (action.type) {
        case "UPDATE_SESSION": {
            return {
                ...state,
                sessionToken: action.session.sessionToken,
                expires: action.session.expires,
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
};

const SessionContextProvider = ({
    children,
    initialState,
}: {
    children: JSX.Element | JSX.Element[];
    initialState: SessionState;
}): JSX.Element | null => {
    const { gameParams } = useGameParamsContext();
    const jwt = gameParams.jwt;

    const [state, dispatch] = React.useReducer(reducer, initialState);

    React.useEffect(() => {
        const now = new Date();
        if (Number(state.expires) - Number(now) < REFRESH_TOKEN_LIMIT) {
            refreshSession();
        }
    }, [state.expires, jwt]);

    const refreshSession = () => {
        if (jwt) {
            authenticate(jwt).then((session) => {
                if (session) {
                    dispatch({
                        type: "UPDATE_SESSION",
                        session,
                    });
                    writeToLocalStorage(session);
                }
            });
        }
    };

    return (
        <SessionContext.Provider value={{ state, actions: { refreshSession } }}>
            {children}
        </SessionContext.Provider>
    );
};

export const withSessionContextProvider =
    (Component: typeof React.Component) => (props: Record<string, unknown>) => {
        return (
            <SessionContextProvider initialState={readFromLocalStorage()}>
                <Component {...props} />
            </SessionContextProvider>
        );
    };

export const useSessionContext = () => React.useContext(SessionContext);

const readFromLocalStorage = (): SessionState => {
    try {
        const data = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY) ?? "");
        const session = SessionState.parse(data);
        return session;
    } catch (error) {
        return defaultState;
    }
};

const writeToLocalStorage = (session: SessionState) => {
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(session));
};
