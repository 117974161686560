import React from "react";
import classNames from "classnames";

import { Pressable } from "../pressable";

import styles from "./styles.modules.scss";
import { useElementOnScreen } from "../../hooks/use-element-on-screen";

type ButtonType = {
    animationSize?: "none" | "small" | "large";
    children: JSX.Element | JSX.Element[] | string;
    onClick: () => void;
    variant?: string;
    disabled?: boolean;
};

export const ButtonView = ({
    animationSize,
    children,
    onClick,
    variant,
    disabled,
}: ButtonType) => {
    const [ref, isVisible] = useElementOnScreen<HTMLDivElement>();
    const [isExtraWide, setIsExtraWide] = React.useState(false);

    const button = (
        <button
            type="button"
            className={`container ${styles.container} `}
            disabled={disabled}
        >
            {children}
        </button>
    );

    React.useEffect(() => {
        if (ref.current) {
            setIsExtraWide((ref.current?.textContent?.length ?? 0) > 16);
        }
    }, [ref]);

    return (
        <div
            ref={ref}
            className={classNames([
                "button",
                styles.button,
                variant,
                styles[variant ?? ""],
                {
                    visible: isVisible,
                    extrawide: isExtraWide,
                },
            ])}
            onClick={onClick}
        >
            {animationSize === "none" ? (
                button
            ) : (
                <Pressable animationSize={animationSize}>{button}</Pressable>
            )}
        </div>
    );
};
