import fetch from "isomorphic-unfetch";

type loadUntilItSucceedsUtil = (
    url: string,
    onSuccess: (raw: Response) => void,
    onError: (error: Error) => void,
    delay?: number,
    max?: number
) => () => void;

type Load = (retries?: number) => Promise<Response>;

export const loadUntilItSucceeds: loadUntilItSucceedsUtil = (
    url,
    onSuccess,
    onError,
    delay = 1 * 1000,
    max = 0
) => {
    let cancel = false;

    const load: Load = (retries = 0) =>
        new Promise((resolve, reject) => {
            if (max && retries > max)
                throw new Error(
                    `Max retries have been reached for the "${url}" url!`
                );

            const nextRetryIn = retries ? delay : 0;

            setTimeout(
                () =>
                    fetch(url)
                        .then((data) => {
                            if (!data.ok) throw data;

                            return cancel || resolve(data);
                        })
                        .catch(() => {
                            if (!cancel) {
                                return load(retries + 1)
                                    .then(resolve)
                                    .catch(reject);
                            }
                        }),
                nextRetryIn
            );
        });

    load().then(onSuccess).catch(onError);

    return () => (cancel = true);
};
