import React from "react";

import {
    TYPE_ARTICLE_BODY_BUTTON,
    TYPE_ARTICLE_BODY_FEATURE,
    TYPE_ARTICLE_BODY_IMAGE,
    TYPE_ARTICLE_BODY_TEXT,
    TYPE_ARTICLE_BODY_VIDEO,
} from "../../../constants/types";

import { BlockButton } from "./button";
import { BlockFeature } from "./feature";
import { BlockHtml } from "./html";
import { BlockImage } from "./image";
import { BlockVideo } from "./video";

import styles from "./styles.modules.scss";

export const getBlockView = (props: InboxArticleBlock): JSX.Element => {
    switch (props.type) {
        case TYPE_ARTICLE_BODY_BUTTON:
            return <BlockButton {...props} />;
        case TYPE_ARTICLE_BODY_FEATURE:
            return <BlockFeature {...props} />;
        case TYPE_ARTICLE_BODY_IMAGE:
            return <BlockImage {...props} />;
        case TYPE_ARTICLE_BODY_TEXT:
            return <BlockHtml {...props} />;
        case TYPE_ARTICLE_BODY_VIDEO:
            return <BlockVideo {...props} />;
        default:
            return <div>[MISSING COMPONENT]</div>;
    }
};

export const BlockView = (props: InboxArticleBlock) => (
    <div className={`block ${styles.component}`}>{getBlockView(props)}</div>
);
