import { getTimeLeft } from "../../utils/get-time-left";

export const getFormattedMessageProps = (
    timeStamp: number,
    limitInMilliseconds: number
): undefined | { id: string; values: Record<string, number> } => {
    const timeSinceNow = Date.now() - timeStamp;
    const { days, hours, minutes } = getTimeLeft(timeSinceNow);

    const { totalDays, totalHours, totalMinutes } =
        getTimeLeft(limitInMilliseconds);

    const getProps = (id: string, x: number) => ({
        id: `globalText.${id}`,
        values: {
            x,
        },
    });

    if (timeSinceNow < 0 || timeSinceNow > limitInMilliseconds) {
        return undefined;
    }

    if (days && days < totalDays) {
        return getProps(days > 1 ? "langDaysAgo" : "langDayAgo", days);
    }

    if (hours && hours < totalHours) {
        return getProps(hours > 1 ? "langHoursAgo" : "langHourAgo", hours);
    }

    if (minutes && minutes < totalMinutes) {
        return getProps(
            minutes > 1 ? "langMinutesAgo" : "langMinuteAgo",
            minutes
        );
    }

    return getProps("langMinutesAgo", 0);
};
