import {
    TYPE_ARTICLE_BODY_BUTTON,
    TYPE_ARTICLE_BODY_FEATURE,
    TYPE_ARTICLE_BODY_IMAGE,
    TYPE_ARTICLE_BODY_TEXT,
    TYPE_ARTICLE_BODY_VIDEO,
    TYPE_ARTICLE_COMMUNITY_LIVE_STREAM,
    TYPE_ARTICLE_COMMUNITY_VIDEO,
    TYPE_ARTICLE_NEWS_EXTERNAL,
    TYPE_ARTICLE_NEWS_IMAGE,
    TYPE_ARTICLE_NEWS_REGULAR,
    TYPE_ARTICLE_NEWS_VIDEO,
    TYPE_ARTICLE_ESPORT_NEWS_REGULAR,
    TYPE_CTA_LINK_ARTICLE,
    TYPE_CTA_LINK_URL,
    TYPE_ESPORT_REWATCH_MATCH_ENTRY,
    UNKNOWN_TYPE,
    ARTICLE_TYPE,
    CTA_LINK_TYPE,
    ARTICLE_BLOCK_TYPE,
} from "../constants/types";

export const parseArticleType = (type: string): ARTICLE_TYPE | UNKNOWN_TYPE => {
    switch (type.toLowerCase()) {
        case "externalnewsentry":
            return TYPE_ARTICLE_NEWS_EXTERNAL;
        case "imagenewsentry":
            return TYPE_ARTICLE_NEWS_IMAGE;
        case "esportrewatchmatchentry":
            return TYPE_ESPORT_REWATCH_MATCH_ENTRY;
        case "newsentry":
            return TYPE_ARTICLE_NEWS_REGULAR;
        case "videonewsentry":
            return TYPE_ARTICLE_NEWS_VIDEO;
        case "esportnewsentry":
            return TYPE_ARTICLE_ESPORT_NEWS_REGULAR;
        case "communityentry":
            return TYPE_ARTICLE_COMMUNITY_VIDEO;
        case "livestreamcommunityentry":
            return TYPE_ARTICLE_COMMUNITY_LIVE_STREAM;
        default:
            return UNKNOWN_TYPE;
    }
};

export const parseCTALinkType = (
    type: string
): CTA_LINK_TYPE | UNKNOWN_TYPE => {
    switch (type.toLowerCase()) {
        case "article":
            return TYPE_CTA_LINK_ARTICLE;
        case "url":
            return TYPE_CTA_LINK_URL;
        default:
            return UNKNOWN_TYPE;
    }
};

export const parseArticleBlockType = (
    type: string
): ARTICLE_BLOCK_TYPE | UNKNOWN_TYPE => {
    switch (type.toLowerCase()) {
        case "buttonblock":
            return TYPE_ARTICLE_BODY_BUTTON;
        case "featureblock":
            return TYPE_ARTICLE_BODY_FEATURE;
        case "imageblock":
            return TYPE_ARTICLE_BODY_IMAGE;
        case "textblock":
            return TYPE_ARTICLE_BODY_TEXT;
        case "videoblock":
            return TYPE_ARTICLE_BODY_VIDEO;
        default:
            return UNKNOWN_TYPE;
    }
};
