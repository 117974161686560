import React from "react";

import { OPEN_LINK } from "../../constants/types/commands";

import { useSendCommand } from "../../hooks/use-send-command";

import { ContentfulSocialLinksView } from "./view";

type SocialLinksType = {
    links?: SocialLink[];
    showBanner?: boolean;
    titleLabel?: string;
};

export const ContentfulSocialLinksController = ({
    links,
    showBanner,
    titleLabel,
}: SocialLinksType) => {
    const sendCommand = useSendCommand();

    if (!links) {
        return null;
    }

    const linksIsEmpty = Object.keys(links).length === 0;

    if (linksIsEmpty) {
        return null;
    }

    const onLinkClick = (url: string) => sendCommand(OPEN_LINK, url);

    const props = {
        links,
        showBanner,
        onLinkClick,
        titleLabel,
    };

    return <ContentfulSocialLinksView {...props} />;
};
