export const TYPE_EVENT_CHANNEL_TARGETED = "TYPE_EVENT_CHANNEL_TARGETED";
export const TYPE_EVENT_CUSTOM = "TYPE_EVENT_CUSTOM";
export const TYPE_EVENT_LIVE_OPS = "TYPE_EVENT_LIVE_OPS";
export const TYPE_EVENT_POLL = "TYPE_EVENT_POLL";

export type EVENT_TYPE =
    | typeof TYPE_EVENT_CHANNEL_TARGETED
    | typeof TYPE_EVENT_CUSTOM
    | typeof TYPE_EVENT_LIVE_OPS
    | typeof TYPE_EVENT_POLL;
