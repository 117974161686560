import React from "react";
import { FormattedMessage } from "react-intl";

import { Pressable } from "../pressable";
import { Image } from "../image";

import styles from "./styles.modules.scss";

export const ContentfulSocialLinksView = ({
    links,
    onLinkClick,
    titleLabel = "globalText.langFollowUs",
    showBanner = false,
}: {
    links: SocialLink[];
    onLinkClick?: (url: string) => void;
    showBanner?: boolean;
    titleLabel?: string;
}) => (
    <div className={`contentful-social-links ${styles.socialLinks}`}>
        <div className={`title ${styles.title}`}>
            <FormattedMessage id={titleLabel} />
        </div>

        <div
            className={`contentful-social-links-wrapper ${styles.socialLinksWrapper}`}
        >
            {links.map((l) => {
                const { linkUrl, socialLinkImage } = l;

                const inner = (
                    <Pressable>
                        <div
                            className={`contentful-social-link ${styles.socialLink}`}
                        >
                            <Image sources={socialLinkImage} />
                        </div>
                    </Pressable>
                );

                return (
                    <div
                        className={`contentful-press ${styles.press}`}
                        key={`link_${linkUrl ?? socialLinkImage.small.path}`}
                        onClick={() => onLinkClick(linkUrl ?? "")}
                    >
                        {inner}
                    </div>
                );
            })}

            {showBanner && <div className={`banner ${styles.banner}`} />}
        </div>
    </div>
);
