import React from "react";
import styles from "../../colors.modules.scss";

export const LatamS = () => {
    return (
        <svg
            id="latam-s"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1034 576.02"
        >
            <path
                d="m306.02,377.42c-.01,2.15-.91,4.2-2.48,5.66l-65.35,61.94v77.1c-.05,3.31-2.12,6.25-5.22,7.4-1.03.4-2.13.6-3.23.6-2.22.01-4.36-.83-5.98-2.35l-34.23-32.4c-1.57-1.46-2.46-3.51-2.48-5.66v-13.95l-13.14-12.44c-1.57-1.46-2.46-3.51-2.48-5.66v-59.22l-42.92-40.65,120.6-84.52,18.32,17.34h12.25c2.22,0,4.36.83,5.98,2.34l17.87,16.93c1.57,1.46,2.46,3.51,2.48,5.66v61.88Z"
                style={{ fill: styles.latamSColorBg }}
            />
        </svg>
    );
};
