import { useIntl } from "react-intl";
import slugify from "slugify";

import { OPEN_ESPORT_ARTICLE, OPEN_NEWS_ARTICLE } from "../constants/types";

import { useSendCommand } from "./use-send-command";

export const useOpenNewsArticle = () => {
    const { locale: lang } = useIntl();
    const sendCommand = useSendCommand();

    return (id, title = "", section = "news") =>
        sendCommand(
            section === "esport" ? OPEN_ESPORT_ARTICLE : OPEN_NEWS_ARTICLE,
            {
                id,
                lang,
                slug: slugify(title, { strict: true, lower: true }),
            }
        );
};
