import { SET_GAME_DATA } from "../../action-types";

import { setupGetNewState } from "./setup-get-new-state";

export const gameDataReducer = (state, { type, payload }) => {
    const { localizedNamespace, data } = payload;

    const getNewState = setupGetNewState(localizedNamespace, state);

    switch (type) {
        case SET_GAME_DATA:
            return getNewState(data);
        default:
            return state;
    }
};
