import React from "react";

import { useIntl } from "react-intl";

const getTimeLeft = (timeInMilliseconds: number) => {
    const totalSeconds =
        (timeInMilliseconds > 0 ? timeInMilliseconds : 0) / 1000;
    const totalMinutes = totalSeconds / 60;
    const totalHours = totalMinutes / 60;
    const totalDays = totalHours / 24;

    return {
        totalMinutes: Math.max(1, Math.floor(totalMinutes)),
        minutes: Math.max(1, Math.floor(totalMinutes % 60)),

        totalHours: Math.floor(totalHours),
        hours: Math.floor(totalDays * 24),
    };
};

import { TimerView } from "./view";

export const TimerController = ({ countdown, timerLabel }) => {
    const { formatMessage } = useIntl();

    const { hours, minutes, totalHours, totalMinutes } = getTimeLeft(countdown);

    const props = {
        hours: {
            value: hours,
            unit: formatMessage({
                id: "globalText.langHoursAbbreviated",
            }),
            total: totalHours,
        },
        minutes: {
            value: minutes,
            unit: formatMessage({
                id: "globalText.langMinutesAbbreviated",
            }),
            total: totalMinutes,
        },
        label: timerLabel,
    };

    return <TimerView {...props} />;
};
