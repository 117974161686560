import React from "react";

import { GAME_COMMAND, OPEN_LINK } from "../../../../constants/types";
import { useOpenNewsArticle } from "../../../../hooks/use-open-news-article";
import { useSendCommand } from "../../../../hooks/use-send-command";

import { BlockButtonView } from "./view";

export const BlockButtonController = ({
    image,
    label,
    position,
    url,
    urlType,
    variant,
}: InboxArticleButtonBlock) => {
    const openNewsArticle = useOpenNewsArticle();
    const sendCommand = useSendCommand();

    const handleClick = () => {
        switch (urlType) {
            case "article":
                return openNewsArticle(url.id, label);
            case "command":
                return sendCommand(GAME_COMMAND, url);
            default:
                return sendCommand(OPEN_LINK, url);
        }
    };

    const props = {
        image,
        label,
        onClick: handleClick,
        position,
        variant: variant,
    };

    return <BlockButtonView {...props} />;
};
