import React, { useState } from "react";

import { getArrayInSets } from "../../utils/get-array-in-sets";

import { ListView } from "./view";

type ListControllerType<T> = {
    items: T[][];
    Item: (props: T) => JSX.Element;
    columns?: number;
    perPage?: number;
    footer: JSX.Element | null;
    header: JSX.Element | null;
};

export const ListController = ({
    items,
    Item,
    columns = 1,
    perPage = 3,
    header,
    footer,
}: ListControllerType<any>) => {
    const [page, setPage] = useState(1);

    const loadMore = () => setPage(page + 1);
    const lastItem = page * perPage;
    const more = lastItem < items.length;

    const props = {
        items:
            columns === 1
                ? items.slice(0, lastItem)
                : getArrayInSets(items.slice(0, lastItem), columns),

        Item,
        columns,
        loadMore,
        more,
        footer,
        header,
    };

    return <ListView {...props} />;
};
