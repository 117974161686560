import { TYPE_ARTICLE_COMMUNITY_LIVE_STREAM } from "../../constants/types";

export const filterLiveArticles = (articles: InboxArticle[]) =>
    articles.filter(({ livestreamEndDate, livestreamStartDate, type }) => {
        if (type !== TYPE_ARTICLE_COMMUNITY_LIVE_STREAM) return true;

        if (!livestreamEndDate || !livestreamStartDate) {
            return true; // TODO is this correct
        }

        const now = Date.now();

        return !(livestreamStartDate > now || now > livestreamEndDate);
    });
