export const setupGetNewState = (localizedNamespace, state) => (data) => {
    const { settings } = state;
    const { game } = settings;

    return {
        ...state,
        settings: {
            ...settings,
            game: {
                ...game,
                [localizedNamespace]: data,
            },
        },
    };
};
