import React from "react";

import styles from "./styles.modules.scss";

export const TimeUnitView = ({ timeUnit: { value, unit } }) => (
    <div className={`time-unit ${styles.timeUnit}`}>
        <div className={`value ${styles.value}`}>{value}</div>

        <div className={`unit ${styles.unit}`}>{unit}</div>
    </div>
);
