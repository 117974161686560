import React from "react";
import { useTime } from "../../../hooks/use-time";
import { EsportSlide, LiveSlide } from "../types";

type BannerProps = {
    view: (
        props: Omit<BannerProps, "view"> & { currentTime: Date }
    ) => JSX.Element;
    slides: EsportSlide[];
};

const IncomingLiveEventsBannerController = (props: BannerProps) => {
    const currentTime = useTime();

    const { slides, view: IncomingLiveEventsBannerView } = props;

    if (!slides.length) {
        return null;
    }

    const upAndComingLiveEvents = slides.filter(
        (s): s is LiveSlide =>
            s.type === "liveEntry" &&
            new Date(s.livestreamStartTime ?? Date.now()) > currentTime
    );

    const slidesWithoutExpiredLives = upAndComingLiveEvents.filter((s) => {
        return new Date(s.livestreamEndTime ?? Date.now()) > currentTime;
    });

    if (!slidesWithoutExpiredLives.length) {
        return null;
    }

    return (
        <IncomingLiveEventsBannerView
            slides={slidesWithoutExpiredLives}
            currentTime={currentTime}
        />
    );
};

export { IncomingLiveEventsBannerController };
