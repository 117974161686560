import React from "react";
import styles from "./styles.modules.scss";

import { useTime } from "supercell-frontend-base/src/hooks/use-time";
import { useSendCommand } from "supercell-frontend-base/src/hooks/use-send-command";
import { useOpenNewsArticle } from "supercell-frontend-base/src/hooks/use-open-news-article";
import { Button } from "supercell-frontend-base/src/components/button";
import { OPEN_LINK } from "supercell-frontend-base/src/constants/types/commands";
import { Image } from "supercell-frontend-base/src/components/image";
import { EsportCarouselProps } from "supercell-frontend-base/src/components/esport/esport-carousel/esport-props";
import { SwiperCarousel } from "supercell-frontend-base/src/components/swiper-carousel/swiper-carousel";

import {
    EsportSlide,
    EventSlide,
    Cta,
    LiveSlide,
} from "supercell-frontend-base/src/components/esport/types";

const CarouselSlide = (props: EsportSlide) => {
    switch (props.type) {
        case "liveEntry":
            return <CarouselLiveSlide {...props} />;
        case "eventEntry":
            return <CarouselEventSlide {...props} />;
    }
};

const CarouselEventSlide = (props: EventSlide) => {
    const sendCommand = useSendCommand();
    const openNewsArticle = useOpenNewsArticle();

    const ctas = (props.ctaButtons ?? []).filter((c): c is Cta => Boolean(c));

    const onLinkClick = (cta: Cta) => {
        if (cta.urlType === "article") {
            openNewsArticle(cta.url.id, "", "esport");
            return;
        }

        sendCommand(OPEN_LINK, cta.url);
    };

    return (
        <div className={`${styles["carousel-slide"]}`}>
            <div className={styles.content}>
                <div className={styles["carousel-image"]}>
                    {props.carouselImage && (
                        <Image sources={props.carouselImage} />
                    )}
                </div>

                <div className={styles["carousel-content"]}>
                    <div className={styles.titles}>
                        <h2 className={styles.title}>{props.title}</h2>

                        {props.subtitle && (
                            <h3 className={styles.subtitle}>
                                {props.subtitle}
                            </h3>
                        )}
                    </div>

                    <div className={styles.ctas}>
                        {ctas.map((cta, ind) => {
                            return (
                                <Button
                                    onClick={() => onLinkClick(cta)}
                                    key={ind}
                                    {...cta}
                                    variant={cta.variant}
                                >
                                    <span className={styles.buttonTextWrapper}>
                                        {cta.label}
                                    </span>
                                </Button>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

const CarouselLiveSlide = (props: LiveSlide) => {
    const startDate = new Date(props.livestreamStartTime ?? Date.now());
    const endDate = new Date(props.livestreamEndTime ?? Date.now());

    const currentDate = useTime();

    const sendCommand = useSendCommand();
    const openNewsArticle = useOpenNewsArticle();

    const onLinkClick = (cta: Cta) => {
        if (cta.urlType === "article") {
            openNewsArticle(cta.url.id, "", "esport");
            return;
        }

        sendCommand(OPEN_LINK, cta.url);
    };

    const isLive = currentDate >= startDate && currentDate <= endDate;

    const ctas = (
        isLive
            ? [props.watchLiveButton]
            : [props.readMoreButton, props.subscribeButton]
    ).filter((c): c is Cta => Boolean(c));

    return (
        <div
            className={`${styles["carousel-slide"]} ${
                isLive ? styles["live"] : ""
            }`}
        >
            <div className={styles.content}>
                <div className={styles["carousel-image"]}>
                    {props.carouselImage && (
                        <Image sources={props.carouselImage} />
                    )}
                </div>

                <div className={styles["carousel-content"]}>
                    {isLive ? (
                        <div className={styles["live-banner"]}>
                            <span>{props.liveLabel}</span>
                        </div>
                    ) : null}

                    <div className={styles.titles}>
                        <h2 className={styles.title}>{props.title}</h2>

                        {props.subtitle && (
                            <h3 className={styles.subtitle}>
                                {props.subtitle}
                            </h3>
                        )}
                    </div>

                    <div className={styles.ctas}>
                        {ctas.map((cta, ind) => {
                            return (
                                <Button
                                    onClick={() => onLinkClick(cta)}
                                    key={ind}
                                    {...cta}
                                    variant={cta.variant}
                                >
                                    {cta.label}
                                </Button>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

const EsportCarouselControllerView = (props: EsportCarouselProps) => {
    const { locale, slides } = props;

    return (
        <div className={`esport-carousel ${styles.carousel}`}>
            <SwiperCarousel
                locale={locale}
                Slide={CarouselSlide}
                slides={slides}
            />
        </div>
    );
};

export { EsportCarouselControllerView };
