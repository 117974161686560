import React, { useContext } from "react";

import { Grid } from "supercell-frontend-base/src/components/grid";
import { List } from "supercell-frontend-base/src/components/list";
import { ListArticle } from "supercell-frontend-base/src/components/list/article";
import { Tab } from "supercell-frontend-base/src/components/inbox/tab";
import { RegionModal } from ".";
import { EsportSelectPrompt } from "./esport-select-prompt";
import { EsportContext } from "./store";
import { IncomingLiveEventsBannerView } from "./esport-incoming-events-banner/view";

import { esportParser } from "supercell-frontend-base/src/parsers/esport";

import styles from "./styles.modules.scss";
import { ContentfulSocialLinks } from "supercell-frontend-base/src/components/contentful-social-links";
import { EsportCarousel } from "supercell-frontend-base/src/components/esport/esport-carousel";
import { EsportCarouselControllerView } from "./esport-carousel/view";
import {
    Cta,
    EsportSlide,
    LiveSlide,
} from "supercell-frontend-base/src/components/esport/types";
import { IncomingLiveEventsBanner } from "supercell-frontend-base/src/components/esport/esport-incoming-events-banner";

export const EsportView = ({ filterArticles }) => {
    return (
        <Tab namespace="esport" parser={esportParser}>
            {({
                data: { articles, carouselItems, socialLinks, globals },
                locale,
            }) => {
                const { state } = useContext(EsportContext);
                const { selectedRegions } = state;

                const filteredArticles = filterArticles(articles);

                const articlesForSelecterRegion = filteredArticles.filter(
                    (a: InboxEsportArticle) =>
                        selectedRegions.some((r) => a.esportRegions.includes(r))
                );

                const carouselItemsForSelectedRegion = carouselItems.filter(
                    (c: EsportSlide) =>
                        selectedRegions.some((r) => c.esportRegions.includes(r))
                );

                const carouselItemsWithoutInvalidLinks =
                    carouselItemsForSelectedRegion.map((c: EsportSlide) => {
                        const isValidCtaButton = (c?: Cta) => {
                            if (!c) {
                                return false;
                            }

                            if (c.type === "url") {
                                return true;
                            }

                            const articleId = c.url.id;

                            const matchingArticle =
                                articlesForSelecterRegion.find(
                                    (a: InboxEsportArticle) =>
                                        a.id === articleId
                                );

                            if (!matchingArticle) {
                                return false;
                            }

                            return true;
                        };

                        if (c.type === "liveEntry") {
                            const isReadMoreValid = isValidCtaButton(
                                c.readMoreButton
                            );

                            return isReadMoreValid
                                ? c
                                : {
                                      ...c,
                                      readMoreButton: undefined,
                                  };
                        }

                        const buttons = c.ctaButtons?.filter(isValidCtaButton);

                        return {
                            ...c,
                            ctaButtons: buttons,
                        };
                    });

                return (
                    <div
                        className={`esport ${styles.esport} ${
                            locale.rtl ? "rtl" : ""
                        }`}
                    >
                        <Grid>
                            <div className={`articles ${styles.articles}`}>
                                <List
                                    header={
                                        <>
                                            <IncomingLiveEventsBanner
                                                view={
                                                    IncomingLiveEventsBannerView
                                                }
                                                slides={
                                                    carouselItemsWithoutInvalidLinks as LiveSlide[]
                                                }
                                            />

                                            <EsportCarousel
                                                view={
                                                    EsportCarouselControllerView
                                                }
                                                locale={locale}
                                                slides={
                                                    carouselItemsWithoutInvalidLinks as EsportSlide[]
                                                }
                                            />
                                        </>
                                    }
                                    Item={ListArticle}
                                    items={articlesForSelecterRegion}
                                    columns={2}
                                    perPage={4}
                                    footer={
                                        <>
                                            <EsportSelectPrompt
                                                globals={globals}
                                            />

                                            <ContentfulSocialLinks
                                                links={socialLinks}
                                            />
                                        </>
                                    }
                                />
                            </div>
                        </Grid>

                        <RegionModal globals={globals} />
                    </div>
                );
            }}
        </Tab>
    );
};
