import React from "react";

import { ErrorBoundary } from "../components/error-boundary";

type WithErrorBoundaryProps = {
    error?: Error;
};

export const withErrorBoundary =
    (Component) => (props: WithErrorBoundaryProps) => {
        const { error, locale } = props;

        return (
            <ErrorBoundary error={error} locale={locale}>
                <Component {...props} />
            </ErrorBoundary>
        );
    };
