import React from "react";

export const withFilteredProps = (Component) => (props) => {
    const { children, context, data, error, id, locale } = props;

    const { state } = context;
    const { settings } = state;
    const { userSettings } = settings;
    const loading = !data;

    const filteredProps = {
        children,
        data,
        error,
        id,
        locale,
        loading,
        userSettings,
    };

    return <Component {...filteredProps} />;
};
