import React from "react";

import { TabContainer } from "./container";

type TabType = (props: {
    id?;
    namespace: string;
    parser: (data) => {
        articles;
        globals;
        events?;
        socialLinks?;
    };
    children: (props: {
        locale: Locale;
        data: GameData;
        userSettings;
    }) => JSX.Element;
}) => JSX.Element;

export const Tab: TabType = TabContainer;

(Tab as React.FC)["displayName"] = "Tab";
