import { TimerController } from "./controller";

type TimerType = (props: {
    countdown: number;
    timerLabel: string;
}) => JSX.Element;

export const Timer: TimerType = TimerController;

Timer["displayName"] = "Timer";
