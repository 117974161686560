import React from "react";
import { useIntl } from "react-intl";

import { parseLang } from "../../parsers/lang";
import { getLocale } from "../../utils/get-locale";
import { generateLocaleStyle } from "../../utils/generate-locale-style";

import { ContentCardView } from "./view";

export type ContentCardProps = {
    id: number | string;
    children: JSX.Element | JSX.Element[];
    lang: string;
    like?: {
        counter: number;
        liked: boolean;
    } | null;
    newUntil?: number | null;
    pinned?: boolean;
    localization: LocalizationConfig;
};

export const ContentCardController = ({
    children,
    id,
    lang,
    localization,
    newUntil,
    pinned,
}: ContentCardProps) => {
    const intl = useIntl();
    const isNew =
        newUntil === undefined || newUntil === null
            ? false
            : newUntil > Date.now();

    const locale = getLocale(localization, lang);
    const style = generateLocaleStyle(locale);
    const { direction } = style;

    const parsedLang = parseLang(lang);

    const props = {
        direction,
        id,
        intl,
        isNew,
        lang: parsedLang,
        pinned,
        style,
    };

    return <ContentCardView {...props}>{children}</ContentCardView>;
};
