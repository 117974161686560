import React from "react";

import { VoteSlideController } from "./controller";

type VoteSlideType = (props: {
    background?: ImageSourceSets;
    title: string;
    pollTitle: string;
    hideTitle: boolean;
    options: { id: number; image: ImageSourceSets; title: string }[];
}) => JSX.Element;

export const VoteSlide: VoteSlideType = VoteSlideController;

(VoteSlide as React.FC)["displayName"] = "VoteSlide";
