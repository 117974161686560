import React from "react";
import { z } from "zod";
import {
    vote as postVote,
    getVote as getVoteApi,
    getPollInfo,
    PollInfo,
} from "../clients/poll";
import { useSessionContext } from "./session";
import { useSendCommand } from "../hooks/use-send-command";

const LOCALSTORAGE_KEY = "poll-votes";

const Votes = z.record(z.union([z.string(), z.null()]));
type Votes = z.infer<typeof Votes>;

type VoteSuccess = {
    status: "ok";
    pollId: string;
    optionId: string;
};
type VoteFailure = {
    status: "fail";
};
type VoteResult = VoteSuccess | VoteFailure;

type VotingState = "idle" | "voting" | "fail";

type PollState = {
    activePollId: string | null;
    votingState: VotingState;
    votes: Votes;
    polls: Record<string, PollInfo>;
};

const defaultState: PollState = {
    activePollId: null,
    votingState: "idle",
    votes: {},
    polls: {},
};

type PollAction =
    | { type: "OPEN_POLL_MODAL"; activePollId: string }
    | { type: "CLOSE_POLL_MODAL" }
    | { type: "UPDATE_VOTE"; pollId: string; optionId: string | null }
    | { type: "UPDATE_POLL_INFO"; pollId: string; pollInfo: PollInfo }
    | { type: "START_VOTING" }
    | { type: "FINISH_VOTING"; result: VoteResult };

const PollContext = React.createContext<{
    state: PollState;
    canVote: (pollId: string) => boolean;
    actions: {
        closeModal: () => void;
        getVote: (pollId: string) => void;
        openModal: (pollId: string) => void;
        refreshPollInfo: (pollId: string) => void;
        vote: (optionId: string) => Promise<[void, void]>;
    };
}>({
    state: defaultState,
    canVote: () => false,
    actions: {
        closeModal: () => null,
        getVote: () => null,
        openModal: () => null,
        refreshPollInfo: () => null,
        vote: () => Promise.resolve([undefined, undefined]),
    },
});

const reducer = (state: PollState, action: PollAction): PollState => {
    switch (action.type) {
        case "OPEN_POLL_MODAL": {
            return {
                ...state,
                activePollId: action.activePollId,
                votingState: "idle",
            };
        }
        case "CLOSE_POLL_MODAL": {
            return {
                ...state,
                activePollId: null,
            };
        }
        case "UPDATE_VOTE": {
            const votes = writeVoteToLocalStorage(
                action.pollId,
                action.optionId
            );
            return {
                ...state,
                votes,
            };
        }
        case "UPDATE_POLL_INFO": {
            const polls = {
                ...state.polls,
                [action.pollId]: action.pollInfo,
            };
            return {
                ...state,
                polls,
            };
        }
        case "START_VOTING": {
            return {
                ...state,
                votingState: "voting",
            };
        }
        case "FINISH_VOTING": {
            if (action.result.status === "ok") {
                const votes = writeVoteToLocalStorage(
                    action.result.pollId,
                    action.result.optionId
                );
                return {
                    ...state,
                    votes,
                    votingState: "idle",
                };
            }
            return {
                ...state,
                votingState: "fail",
            };
        }
        default:
            throw new Error("PollContext: Unhandled action type");
    }
};

const PollContextProvider = ({
    children,
    initialState,
}: {
    children: JSX.Element | JSX.Element[];
    initialState: PollState;
}): JSX.Element | null => {
    const {
        state: { sessionToken },
        actions: { refreshSession },
    } = useSessionContext();

    const sendCommand = useSendCommand();

    const [state, dispatch] = React.useReducer(reducer, initialState);

    const onPopState = React.useCallback((event: PopStateEvent) => {
        if (event.state?.home) {
            closeModal();
        }
    }, []);

    React.useEffect(() => {
        window.addEventListener("popstate", onPopState);

        return () => {
            window.removeEventListener("popstate", onPopState);
        };
    }, []);

    const getVote = (pollId: string) => {
        if (!sessionToken) {
            return;
        }
        getVoteApi(pollId, sessionToken).then((optionId) => {
            dispatch({ type: "UPDATE_VOTE", pollId, optionId });
        });
    };

    const refreshPollInfo = (pollId: string) => {
        return getPollInfo(pollId)
            .then((pollInfo) => {
                dispatch({
                    type: "UPDATE_POLL_INFO",
                    pollId,
                    pollInfo,
                });
            })
            .catch(() => {
                refreshSession();
            });
    };

    const vote = (optionId: string) => {
        if (!sessionToken || !state.activePollId) {
            return Promise.resolve([undefined, undefined] as [void, void]);
        }
        const pollId = state.activePollId;
        dispatch({ type: "START_VOTING" });
        return Promise.all([
            postVote(pollId, optionId, sessionToken)
                .then((response) => {
                    if (response.type === "error") {
                        throw new Error(response.message);
                    }

                    dispatch({
                        type: "FINISH_VOTING",
                        result: { status: "ok", pollId, optionId },
                    });
                })
                .catch(() => {
                    dispatch({
                        type: "FINISH_VOTING",
                        result: { status: "fail" },
                    });
                    refreshSession();
                }),
            refreshPollInfo(pollId),
        ]);
    };

    const openModal = (pollId: string) => {
        sendCommand("SHOW_BACK_BUTTON", true);
        window.history.pushState({ home: false }, "");
        dispatch({ type: "OPEN_POLL_MODAL", activePollId: pollId });
    };

    const closeModal = () => {
        sendCommand("SHOW_BACK_BUTTON", false);
        dispatch({ type: "CLOSE_POLL_MODAL" });
    };

    const closeModalAction = () => {
        window.history.replaceState({ home: true }, "");
        closeModal();
    };

    const canVote = (pollId: string) => {
        const hasVoted = pollId in state.votes && state.votes[pollId] !== null;
        return !hasVoted && sessionToken !== null;
    };

    return (
        <PollContext.Provider
            value={{
                state,
                canVote,
                actions: {
                    closeModal: closeModalAction,
                    getVote,
                    openModal,
                    refreshPollInfo,
                    vote,
                },
            }}
        >
            {children}
        </PollContext.Provider>
    );
};

export const withPollContextProvider =
    (Component: typeof React.Component) => (props: Record<string, unknown>) => {
        return (
            <PollContextProvider
                initialState={{
                    ...defaultState,
                    votes: readVotesFromLocalStorage(),
                }}
            >
                <Component {...props} />
            </PollContextProvider>
        );
    };

export const usePollContext = () => React.useContext(PollContext);

const readVotesFromLocalStorage = (): Votes => {
    try {
        const data = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY) ?? "");
        const votes = Votes.parse(data);
        return votes;
    } catch (error) {
        localStorage.removeItem(LOCALSTORAGE_KEY);
        return {};
    }
};

const writeVoteToLocalStorage = (pollId: string, optionId: string | null) => {
    const currentVotes = readVotesFromLocalStorage();
    const votes = { ...currentVotes, [pollId]: optionId };
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(votes));
    return votes;
};
