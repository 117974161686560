import React from "react";
import { FormattedMessage } from "react-intl";
import { VideoProvider } from "../../constants/types";

import { Image } from "../image";

import { LiveIcon } from "./live-icon";
import { ProviderIcon } from "./provider-icon";

import styles from "./styles.modules.scss";
import { useElementAppearsOnScreen } from "../../hooks/use-element-on-screen";
import classNames from "classnames";

type VideViewType = {
    caption?: {
        author: string;
        title: string;
    };
    hideFilter?: boolean;
    hideProvider?: boolean;
    live?: boolean;
    provider: VideoProvider;
    thumbnail: ImageSourceSets;
    style: { [key in string]: string };
};

export const VideoView = ({
    caption,
    hideFilter,
    hideProvider,
    live,
    provider,
    style: { direction },
    thumbnail,
}: VideViewType) => {
    const [playIconRef, playIconVisible] =
        useElementAppearsOnScreen<HTMLDivElement>();

    return (
        <div
            className={`video ${styles.video} ${direction} ${
                styles[direction] || ""
            }`}
        >
            <div className={`thumbnail ${styles.thumbnail}`}>
                <Image sources={thumbnail} />
            </div>

            <div className={`overlay ${styles.overlay}`}>
                <div className={`layer ${styles.layer} inactive`}>
                    {!hideFilter && (
                        <div className={`filter ${styles.filter}`} />
                    )}

                    {caption && (caption.title || caption.author) && (
                        <div className={`caption  ${styles.caption}`}>
                            {caption.title && (
                                <div className={`title ${styles.title}`}>
                                    {caption.title}
                                </div>
                            )}

                            {caption.author && (
                                <div className={`author ${styles.author}`}>
                                    <FormattedMessage id="globalText.langBy">
                                        {(message) => (
                                            <div>{`${message} ${caption.author}`}</div>
                                        )}
                                    </FormattedMessage>
                                </div>
                            )}
                        </div>
                    )}

                    <div
                        ref={playIconRef}
                        className={classNames([
                            "play-icon",
                            `play-icon-${provider}`,
                            styles.playIcon,
                            {
                                visible: playIconVisible,
                            },
                        ])}
                    />
                </div>

                {!hideProvider && (
                    <div className={`provider ${styles.provider}`}>
                        <ProviderIcon provider={provider} variant={"light"} />
                    </div>
                )}

                {live && (
                    <div className={`live ${styles.live}`}>
                        <LiveIcon />
                    </div>
                )}
            </div>
        </div>
    );
};
