import React, { useState } from "react";

import { Provider as LocalizedNamespaceProvider } from "../../contexts/localized-namespace";

export const withLocalizedNamespaceContextProvider =
    (Component: typeof React.Component) => (props: Record<string, unknown>) =>
        (
            <LocalizedNamespaceProvider value={useState()}>
                <Component {...props} />
            </LocalizedNamespaceProvider>
        );
