import {
    LegacyRegion,
    EsportRegion,
} from "supercell-frontend-base/src/components/esport/types";
import * as z from "zod";

import { EsportState, DEFAULT_STATE } from "./store";

const legacyRegionMapping: Record<LegacyRegion, EsportRegion> = {
    emea: "EMEA",
    eeca: "EMEA",
    "sea-anz": "APAC",
    "east-asia": "APAC",
    "mainland-china": "APAC",
    "na-latam-n": "NA & LATAM N",
    "latam-s": "LATAM S",
};

const localStorageRegionKey = "selectedRegions";

const getKey = (locale: string) => `${localStorageRegionKey}-${locale}`;

export const getInboxRegionFromUrl = (): EsportRegion[] => {
    const param = new URLSearchParams(window.location.search).get("regions");

    if (!param) {
        return [];
    }

    const paramString = param.split(",");

    return paramString
        .map((r) => {
            const asEsport = EsportRegion.safeParse(r);

            if (asEsport.success) {
                return asEsport.data;
            }

            const asLegacy = LegacyRegion.safeParse(r);

            if (asLegacy.success) {
                return legacyRegionMapping[asLegacy.data];
            }

            return null;
        })
        .filter((r): r is EsportRegion => r !== null);
};

export const getInboxRegionFromLocalStorage = (
    locale: string
): EsportRegion[] => {
    const regions = localStorage.getItem(getKey(locale));

    if (!regions) {
        return [];
    }

    try {
        const parsed = JSON.parse(regions);

        return z.array(EsportRegion).parse(parsed);
    } catch (_ex) {
        return [];
    }
};

export const setInboxRegionToLocalStorage = (
    regions: EsportRegion[],
    locale = "en"
) => {
    localStorage.setItem(getKey(locale), JSON.stringify(regions));
};

// Source of truth: https://docs.google.com/spreadsheets/d/1pduNbacPUjWafakpuW74L-V5oGFoJxLvaskbKXjMaV0/edit#gid=0
export const getEsportStateForLocale = (locale: string): EsportState => {
    const s = {
        ...DEFAULT_STATE,
    };

    switch (locale) {
        case "de":
        case "it":
        case "tr":
        case "ru":
        case "pl":
        case "fr":
            return {
                ...s,
                canChangeRegion: false,
                selectedRegions: ["EMEA"],
                regionsAvailable: ["EMEA"],
            };
        case "ja":
        case "ko":
        case "cnt":
        case "cns":
            return {
                ...s,
                canChangeRegion: false,
                selectedRegions: ["APAC"],
                regionsAvailable: ["APAC"],
            };
        case "pt":
            return {
                ...s,
                canChangeRegion: false,
                selectedRegions: ["LATAM S"],
                regionsAvailable: ["LATAM S"],
            };
        case "es":
            return {
                ...s,
                selectedRegions: ["EMEA"],
                regionsAvailable: ["EMEA", "NA & LATAM N", "LATAM S"],
            };
        case "en":
        case "fi":
        case "he":
        case "nl":
        case "ar":
            return {
                ...s,
                selectedRegions: ["EMEA"],
                regionsAvailable: ["EMEA", "NA & LATAM N", "LATAM S", "APAC"],
            };
        case "id":
        case "ms":
        case "th":
            return {
                ...s,
                selectedRegions: ["APAC"],
                regionsAvailable: ["EMEA", "NA & LATAM N", "LATAM S", "APAC"],
            };
    }

    return s;
};

export const calculateEsportInitialState = (
    _gameCountry?: string,
    gameLocale: string
): EsportState => {
    const locale = gameLocale.toLowerCase();

    const state = getEsportStateForLocale(locale);

    const possibleUrlRegions = getInboxRegionFromUrl();

    if (possibleUrlRegions.length) {
        return {
            ...state,
            selectedRegions: possibleUrlRegions,
        };
    }

    const possibleLocalStorageRegions = getInboxRegionFromLocalStorage(locale);

    if (possibleLocalStorageRegions.length) {
        return {
            ...state,
            selectedRegions: possibleLocalStorageRegions,
        };
    }

    // We want to show the region selector modal if user has not made a choice and can make a choice
    const isRegionSelectorModalOpen =
        possibleUrlRegions.length === 0 &&
        possibleLocalStorageRegions.length === 0 &&
        state.canChangeRegion;

    return {
        ...state,
        isRegionSelectorModalOpen,
    };
};
