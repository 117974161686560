import { captureManualException } from "../tracing/sentry";
import { z } from "zod";

const base = window.POLL_API_BASE;

if (!base) {
    captureManualException(new Error("Poll API not defined"));
}

if (!("fetch" in window)) {
    captureManualException(new Error("Fetch API not supported"));
}

const SessionApiErrorResponse = z.object({
    message: z.string(),
    type: z.literal("error"),
});
type SessionApiErrorResponse = z.infer<typeof SessionApiErrorResponse>;

export const Session = z.object({
    expires: z.coerce.date(),
    sessionToken: z.string(),
});
export type Session = z.infer<typeof Session>;

const SessionApiAuthenticatedResponse = z.object({
    data: Session,
    type: z.literal("authPost"),
});
type SessionApiAuthenticatedResponse = z.infer<
    typeof SessionApiAuthenticatedResponse
>;

export const SessionApiResponse = z.union([
    SessionApiErrorResponse,
    SessionApiAuthenticatedResponse,
]);
export type SessionApiResponse = z.infer<typeof SessionApiResponse>;

export const authenticate = async (jwt: string): Promise<Session | null> => {
    if (!base) {
        return null;
    }
    try {
        const response = await fetch(`${base}/auth/`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${jwt}`,
            },
        });

        const parsed = SessionApiResponse.parse(await response.json());

        if (parsed.type === "error") {
            return null;
        }

        return parsed.data;
    } catch (error) {
        if (error instanceof Error && !(error instanceof TypeError)) {
            captureManualException(error);
        }
        return null;
    }
};
