export const getTimeLeft = (timeInMilliseconds: number) => {
    const totalSeconds =
        (timeInMilliseconds > 0 ? timeInMilliseconds : 0) / 1000;
    const totalMinutes = totalSeconds / 60;
    const totalHours = totalMinutes / 60;
    const totalDays = totalHours / 24;

    return {
        totalSeconds: Math.floor(totalSeconds),
        seconds: Math.floor(totalSeconds % 60),

        totalMinutes: Math.floor(totalMinutes),
        minutes: Math.floor(totalMinutes % 60),

        totalHours: Math.floor(totalHours),
        hours: Math.floor(totalHours % 24),

        totalDays: Math.floor(totalDays),
        days: Math.floor(totalDays),
    };
};
