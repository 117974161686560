// These two imports will be stripped out by Webpack if no Sentry configuration has been specifiec
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import React from "react";
import {
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
    Routes,
} from "react-router-dom";
import { GameParams } from "validation/game-params";

const sentryExists = !!window.SENTRY_DSN;

export const initialiseSentry = sentryExists
    ? (opts: Sentry.BrowserOptions, buildInfo: BuildInfo): void => {
          Sentry.init({
              maxValueLength: 3000,
              environment: buildInfo.environmentName,
              release: buildInfo.buildId,
              integrations: [
                  new BrowserTracing({
                      routingInstrumentation:
                          Sentry.reactRouterV6Instrumentation(
                              React.useEffect,
                              useLocation,
                              useNavigationType,
                              createRoutesFromChildren,
                              matchRoutes
                          ),
                  }),
              ],
              tracesSampleRate: 0.1,
              ...opts,
          });
      }
    : (_: Sentry.BrowserOptions, __: BuildInfo): void => {
          return;
      };

export const captureManualException = sentryExists
    ? (ex: Error): void => {
          Sentry.captureException(ex);
      }
    : (_: Error): void => {
          return;
      };

export const captureMessage = sentryExists
    ? (msg: string, severity: Sentry.SeverityLevel = "debug"): void => {
          Sentry.captureMessage(msg, severity);
      }
    : (_: string, __: Sentry.SeverityLevel = "debug"): void => {
          return;
      };

export const withSentryTracing = sentryExists
    ? (component: (props: unknown) => JSX.Element) =>
          Sentry.withProfiler(component)
    : (component: (props: unknown) => JSX.Element) => component;

export const withSentryRoutes = sentryExists
    ? () => Sentry.withSentryReactRouterV6Routing(Routes)
    : () => Routes;

export const setUser = sentryExists
    ? (id: number | string) => {
          try {
              Sentry.setUser({ id: String(id) });
          } catch (error) {
              return;
          }
      }
    : () => {
          return;
      };

export const setGameParams = sentryExists
    ? (gameParams: GameParams) => {
          try {
              const { channel, country, platform, quality, xp } = gameParams;
              Sentry.setContext("Game Parameters", {
                  channel,
                  country,
                  platform,
                  quality,
                  xp,
              });
          } catch (error) {
              return;
          }
      }
    : () => {
          return;
      };
