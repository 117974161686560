import React from "react";
import { FormattedMessage } from "react-intl";

import styles from "./styles.modules.scss";

export const LiveIconView = () => (
    <div className={`live-icon ${styles.liveIcon}`}>
        <FormattedMessage id="communityText.langLive" />
    </div>
);
