export const generateLocaleStyle = (locale: Locale) => {
    const { rtl, fonts } = locale;

    const {
        lineHeightRatio,
        primaryFontRatio,
        primaryFont,
        secondaryFont,
        secondaryFontRatio,
        titleBoldValue,
    } = fonts ?? {};

    return {
        direction: rtl ? "rtl" : "ltr",
        fontFamily: primaryFont || "inherit",
        textAlign: rtl ? "right" : "left",
        "--lineHeightRatio": lineHeightRatio || 1,
        "--secondaryFont": secondaryFont || "inherit",
        "--secondaryFontRatio": secondaryFontRatio || 1,
        "--primaryFont": primaryFont || "inherit",
        "--primaryFontRatio": primaryFontRatio || 1,
        "--titleBoldValue": titleBoldValue || "normal",
    };
};
