import React, { useContext } from "react";

import { useIntl } from "react-intl";

import { localizationContext } from "../contexts/localization";

import { getLocale } from "../utils/get-locale";
import { generateLocaleStyle } from "../utils/generate-locale-style";

export const withStyle = (Component) => (props) => {
    const localization = useContext(localizationContext);
    const { locale: lang } = useIntl();

    const locale = getLocale(localization, lang);
    const style = generateLocaleStyle(locale);

    return <Component {...props} style={style} />;
};
