import React, { useEffect } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Pagination } from "swiper";
import { CarouselSlide } from "./slide";
import { ContentCard } from "../content-card";
import classNames from "classnames";
import { PaginationOptions } from "swiper/types";

import "swiper/css";
import "swiper/css/pagination";
import styles from "./styles.modules.scss";

type SlideBackground = {
    height: number;
    width: number;
    path: string;
};

type Slide = {
    id: string;
    ctas: { id: number; label: string }[];
    newUntil: number | null;
    slideId: string;
    title: string;
    isClone?: boolean;
    timerTarget: number;
    timerKind?: InboxEventTimerKind;
    type: "type";
    background?: Record<
        | "smallRetina"
        | "small"
        | "mediumRetina"
        | "medium"
        | "largeRetina"
        | "large",
        SlideBackground
    >;
};

type CarouselProps = {
    locale: {
        lang: string;
    };
    Slide: typeof CarouselSlide;
    slides: Slide[];
    onSlideChange?: (index: number) => void;
};

function iosMajorVersion() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
        const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);

        const majorVersion = v && v[1] ? parseInt(v[1], 10) : null;

        return majorVersion;
    }

    return null;
}

export const SwiperCarousel = ({
    locale,
    Slide,
    slides,
    onSlideChange,
}: CarouselProps) => {
    const useiOS12WorkAround = (iosMajorVersion() ?? Infinity) <= 12;

    const [time, setTime] = React.useState(Date.now());

    useEffect(() => {
        const intervalId = setInterval(() => {
            const currentTime = Date.now();
            setTime(currentTime);
        }, 1000);

        const clear = () => {
            clearInterval(intervalId);
        };

        return clear;
    }, []);

    const filteredSlides = slides.filter((slide) => {
        if (!slide.timerKind) {
            return true;
        }

        if (slide.timerKind === "HIDE_ENTRY") {
            const shouldShow = slide.timerTarget > time;

            return shouldShow;
        }

        return true;
    });

    const paginationProps: PaginationOptions = {
        bulletActiveClass: classNames("selected", styles.selected),
        bulletClass: classNames("page", styles.page),
        enabled: filteredSlides.length > 1,
    };

    return (
        <Swiper
            onSlideChange={(swiper) =>
                onSlideChange ? onSlideChange(swiper.realIndex) : null
            }
            loop={true}
            pagination={paginationProps}
            modules={[Pagination]}
            className={classNames(styles.carousel, "carousel", {
                single: filteredSlides.length === 1,
                multi: filteredSlides.length > 1,
            })}
            onPaginationRender={(_, el) => {
                // There's no option for the container class name, so we use this
                el.classList.add("paginator");
                el.classList.add(styles.paginator ?? "");
            }}
        >
            {filteredSlides.map((slide) => (
                <SwiperSlide key={slide.id} className="slide">
                    {() => {
                        const swiper = useSwiper();

                        return (
                            <div
                                onClick={(e) => {
                                    if (!useiOS12WorkAround) {
                                        return;
                                    }

                                    if (
                                        (e.target as HTMLInputElement)
                                            .nodeName !== "DIV"
                                    ) {
                                        return;
                                    }

                                    swiper.slideNext();
                                }}
                            >
                                <ContentCard id={slide.id} lang={locale.lang}>
                                    <Slide {...slide} />
                                </ContentCard>
                            </div>
                        );
                    }}
                </SwiperSlide>
            ))}
        </Swiper>
    );
};
