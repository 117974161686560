import React from "react";

import { Loader } from "../loader";

import styles from "./styles.modules.scss";

export const LoadingView = ({ light }: { light?: boolean }) => (
    <div className={`loading ${styles.loading}`}>
        <Loader light={light} />
    </div>
);
