import React from "react";

import { Html } from "../../../html";
import { Image } from "../../../image";

import styles from "./styles.modules.scss";

export const BlockFeatureView = ({
    body,
    image,
    position,
    title,
}: InboxArticleFeatureBlock) => {
    const hasBody = !!body;

    return (
        <div
            className={`block-feature ${styles.blockFeature} ${
                position === "right" ? `${styles.right} right` : "left"
            }`}
        >
            {image && (
                <div className={`image ${styles.image}`}>
                    <Image sources={image} cover />
                </div>
            )}

            <div
                className={`content ${styles.content} ${
                    hasBody ? "" : styles["no-body"]
                }`}
            >
                <div className={`title ${styles.title}`}>{title}</div>

                {hasBody && <Html html={body} />}
            </div>
        </div>
    );
};
