import React from "react";
import classNames from "classnames";

import styles from "./styles.modules.scss";

export const CTAIconView = ({ external }: { external: boolean }) => {
    return (
        <div
            className={classNames(
                "cta",
                styles.cta,
                external ? ["external", styles.external] : null
            )}
        >
            <div
                className={classNames(
                    "icon",
                    styles.icon,
                    external ? ["external", styles.external] : []
                )}
            />
        </div>
    );
};
