import React from "react";
import { Pressable } from "../../../pressable";

import styles from "./styles.modules.scss";

import { Label } from "./label";
import { LinkType } from "./type";

export const LinkView = ({
    disabled,
    label,
    LabelComponent,
    labelComponentProps,
    next,
    onClick,
}: LinkType & { onClick: () => void }) => (
    <div
        className={`
            link ${styles.link}
            ${disabled ? `disabled ${styles.disabled}` : ""}
            ${next ? `next ${styles.next}` : ""}
        `}
        onClick={onClick}
    >
        <Pressable>
            <div className={`container ${styles.container}`}>
                <div className={`icon ${styles.icon}`} />

                <div className={`label ${styles.label}`}>
                    <Label
                        label={label}
                        Component={LabelComponent}
                        componentProps={labelComponentProps}
                    />
                </div>
            </div>
        </Pressable>
    </div>
);
