import React from "react";

import { Loading } from "../components/loading";

type WithLoadingViewProps = {
    loading: boolean;
};

export const withLoadingView = (Component) => (props: WithLoadingViewProps) => {
    const { loading } = props;

    return loading ? <Loading /> : <Component {...props} />;
};
