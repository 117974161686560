import React from "react";
import ReactDOM from "react-dom";

import styles from "./styles.modules.scss";
import { WorldMap, NaLatamN, Emea, LatamS, Apac } from "./svg";
import { RegionModalProps } from "./region-modal-props";
import { EsportRegion } from "../types";
import { RegionSelectButton } from "./region-select-button/region-select-button";
import { Button } from "supercell-frontend-base/src/components/button";

const RegionSelectIndicator = (props: { region: EsportRegion }) => {
    switch (props.region) {
        case "APAC":
            return <Apac />;
        case "NA & LATAM N":
            return <NaLatamN />;
        case "EMEA":
            return <Emea />;
        case "LATAM S":
            return <LatamS />;
    }
};

const RegionModalView = (props: RegionModalProps) => {
    const {
        globals,
        selectedRegions,
        regionsAvailable,
        selectRegion,
        closeModal,
        unselectRegion,
    } = props;

    const modalRoot = document.getElementById("modal-root");

    if (!modalRoot) {
        return null;
    }

    const contents = (
        <div className={`region-wrapper ${styles.region}`}>
            <h2>{globals["globalText.esportFollow"]}</h2>

            <div className={`${styles.map}`}>
                <WorldMap />

                {selectedRegions.map((r, ind) => (
                    <RegionSelectIndicator key={ind} region={r} />
                ))}

                <div className={styles["region-buttons"]}>
                    {regionsAvailable.map((r, ind) => {
                        const isSelected = selectedRegions.includes(r);

                        return (
                            <RegionSelectButton
                                disabled={!isSelected}
                                key={ind}
                                region={r}
                                onClick={() =>
                                    isSelected
                                        ? unselectRegion(r)
                                        : selectRegion(r)
                                }
                            />
                        );
                    })}
                </div>
            </div>

            <div className={styles.done}>
                {selectedRegions.length !== 0 && (
                    <Button onClick={() => closeModal()} animationSize="large">
                        <span className={styles.buttonTextWrapper}>
                            {globals["globalText.esportDone"] ?? ""}
                        </span>
                    </Button>
                )}
            </div>
        </div>
    );

    return ReactDOM.createPortal(contents, modalRoot);
};

export default RegionModalView;
