import React from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import { DateOrTime } from "../../date-or-time";

import { Categories } from "./categories";
import { CTAIcon } from "./cta-icon";

import styles from "./styles.modules.scss";

type ContentCardCaptionViewType = {
    author?: string;
    creator?: string;
    cardId: string | number;
    categories?: { title: string; color: string }[];
    ctaHidden?: boolean;
    external?: boolean;
    icon?: string;
    postDate?: number;
    title?: string;
    limit: number;
    provider?: string;
};

export const ContentCardCaptionView = ({
    author,
    cardId,
    categories,
    creator,
    ctaHidden,
    external,
    icon,
    limit,
    postDate,
    title,
    provider,
}: ContentCardCaptionViewType) => (
    <div className={`caption ${styles.caption}`}>
        <div className={`details ${styles.details}`}>
            {categories && categories.length > 0 && (
                <div className={`categories ${styles.categories}`}>
                    <Categories cardId={cardId} categories={categories} />
                </div>
            )}

            {postDate && (
                <div className={`post-date ${styles.postDate}`}>
                    <DateOrTime
                        limitInMilliseconds={limit}
                        timeStamp={postDate}
                    />
                </div>
            )}

            {icon && <img className={`icon ${styles.icon}`} src={icon} />}

            {creator && (
                <div className={`creator ${styles.creator}`}>
                    <div className={`prefix ${styles.prefix}`}>
                        <FormattedMessage id="globalText.langBy" />
                    </div>

                    <div className={`value ${styles.value}`}>{creator}</div>
                </div>
            )}

            {title && <div className={`title ${styles.title}`}>{title}</div>}

            {author && (
                <div className={`author ${styles.author}`}>
                    <div className={`prefix ${styles.prefix}`}>
                        <FormattedMessage id="globalText.langBy" />
                    </div>

                    <div className={`value ${styles.value}`}>{author}</div>
                </div>
            )}
        </div>

        {!ctaHidden && (
            <div className={classNames("cta", styles.cta, provider)}>
                <CTAIcon external={!!external} />
            </div>
        )}
    </div>
);
