import * as z from "zod";

export const EsportRegion = z.enum(["APAC", "EMEA", "LATAM S", "NA & LATAM N"]);

export type EsportRegion = z.infer<typeof EsportRegion>;

export const LegacyRegion = z.enum([
    "emea",
    "latam-s",
    "na-latam-n",
    "sea-anz",
    "eeca",
    "mainland-china",
    "east-asia",
]);

export type LegacyRegion = z.infer<typeof LegacyRegion>;

export type UrlCta = {
    type: "url";
    urlType: "url";
    order: string;
    variant: string;
    url: string;
    label: string;
};

export type ArticleCta = {
    type: "article";
    urlType: "article";
    order: string;
    variant: string;
    label: string;

    url: {
        id: string;
        section: "newsEntries";
    };

    article: {
        id: string;
        section: "newsEntries";
    }[];
};

export type Cta = UrlCta | ArticleCta;

export type LiveSlide = {
    type: "liveEntry";
    title?: string;
    liveLabel?: string;
    subtitle?: string;
    livestreamEndTime?: string;
    livestreamStartTime?: string;
    eventPromotionName?: string;

    carouselImage?: ImageSourceSets;
    eventPromotionLogo?: ImageSourceSets;

    watchLiveButton?: Cta;
    readMoreButton?: Cta;
    subscribeButton?: Cta;
    esportRegions: EsportRegion[];
};

export type EventSlide = {
    type: "eventEntry";
    title?: string;
    subtitle?: string;
    carouselImage?: ImageSourceSets;
    ctaButtons?: Cta[];
    esportRegions: EsportRegion[];
};

export type EsportSlide = LiveSlide | EventSlide;
