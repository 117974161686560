import React from "react";

import { Grid } from "supercell-frontend-base/src/components/grid";
import { List } from "supercell-frontend-base/src/components/list";
import { ListArticle } from "supercell-frontend-base/src/components/list/article";
import { Tab } from "supercell-frontend-base/src/components/inbox/tab";

import { communityParser } from "supercell-frontend-base/src/parsers/community";

import styles from "./styles.modules.scss";

const onceAMinuteInMs = 1000 * 60;

function useFilterOnInterval<T>(
    filter: (article: T) => T,
    articles: T,
    timeout = onceAMinuteInMs
) {
    const [filteredArticles, setFilteredArticles] = React.useState(articles);

    React.useEffect(() => {
        const intervalId = setInterval(
            () => setFilteredArticles(filter(articles)),
            timeout
        );

        return () => clearInterval(intervalId);
    }, [articles, filter, timeout]);

    return filteredArticles;
}

export const CommunityView = ({ filterArticles }) => (
    <Tab namespace="community" parser={communityParser}>
        {({ data: { articles }, locale }) => {
            const filteredArticles = useFilterOnInterval(
                filterArticles,
                articles
            );

            return (
                <div
                    className={`community ${styles.community} ${
                        locale.rtl ? "rtl" : ""
                    }`}
                >
                    <Grid>
                        <div className={`articles ${styles.articles}`}>
                            <List
                                Item={ListArticle}
                                items={filteredArticles}
                                columns={2}
                                perPage={4}
                                footer={null}
                            />
                        </div>
                    </Grid>
                </div>
            );
        }}
    </Tab>
);
