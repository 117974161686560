import { IntlShape } from "react-intl";
import React from "react";
import { GameParams } from "../../../validation/game-params";
import { IntlProvider } from "react-intl";

import styles from "./styles.modules.scss";

export const TabView = ({
    children: Component,
    data,
    intl,
    locale,
    style,
    userSettings,
}: {
    children: Component;
    data: unknown;
    locale: Locale;
    userSettings: GameParams;
    style: { [key in string]: string };
    intl: IntlShape;
}) => (
    <div className={`tab ${styles.tab}`} style={style}>
        <IntlProvider {...intl}>
            <Component
                locale={locale}
                data={data}
                userSettings={userSettings}
            />
        </IntlProvider>
    </div>
);
