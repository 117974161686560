import { mapCTAs } from "./map";

export function sortCTAs(ctas: { [key in string]: InboxCTA }) {
    return mapCTAs(
        Object.values(ctas).sort(
            ({ order: prev }, { order: next }) =>
                parseInt(prev ?? "0") - parseInt(next ?? "0")
        )
    );
}
