import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { SHOW_BACK_BUTTON } from "../constants/types";
import { useSendCommand } from "../hooks/use-send-command";

export const withNoBackButtonOnHome = (Component) => (props) => {
    const location = useLocation();
    const sendCommand = useSendCommand();

    useEffect(() => {
        const { state } = window.history;
        const isHome = state && state.home;

        sendCommand(SHOW_BACK_BUTTON, !isHome);
    }, [location]);

    return <Component {...props} />;
};
