import React from "react";

import { SwiperCarousel } from "supercell-frontend-base/src/components/swiper-carousel/swiper-carousel";
import { CarouselSlide } from "supercell-frontend-base/src/components/swiper-carousel/slide";
import { Grid } from "supercell-frontend-base/src/components/grid";
import { List } from "supercell-frontend-base/src/components/list";
import { ListArticle } from "supercell-frontend-base/src/components/list/article";
import { ContentfulSocialLinks } from "supercell-frontend-base/src/components/contentful-social-links";
import { Tab } from "supercell-frontend-base/src/components/inbox/tab";

import { inboxParser } from "supercell-frontend-base/src/parsers/inbox";

import { filterEntry } from "supercell-frontend-base/src/targeting/targeting";
import { BrawlStarsGameParams } from "validation/game-params";
import { useGameParamsContext } from "supercell-frontend-base/src/stores/gameParams";

import styles from "./styles.modules.scss";

export const NewsView = () => (
    <Tab namespace="news" parser={inboxParser}>
        {({ data, locale }) => {
            const { gameParams } = useGameParamsContext<BrawlStarsGameParams>();

            return (
                <div
                    className={`news ${styles.news} ${locale.rtl ? "rtl" : ""}`}
                >
                    <Grid>
                        {data.events && (
                            <div className={`events ${styles.events}`}>
                                <SwiperCarousel
                                    locale={locale}
                                    Slide={CarouselSlide}
                                    slides={data.events.filter((e) =>
                                        filterEntry(e, gameParams)
                                    )}
                                />
                            </div>
                        )}

                        {data.articles && (
                            <div className={`articles ${styles.articles}`}>
                                <List
                                    perPage={5}
                                    Item={ListArticle}
                                    items={data.articles.filter((e) =>
                                        filterEntry(e, gameParams)
                                    )}
                                    footer={
                                        <ContentfulSocialLinks
                                            links={data.socialLinks}
                                        />
                                    }
                                />
                            </div>
                        )}
                    </Grid>
                </div>
            );
        }}
    </Tab>
);
