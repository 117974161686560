import React from "react";

import { InboxContainer } from "./container";

type InboxType = (props: {
    referenceWidth: number;
    routes: Route[];
    localization: LocalizationConfig;
    error?: Error;
    commander: unknown;
}) => JSX.Element;

export const Inbox: InboxType = InboxContainer;

(Inbox as React.FC)["displayName"] = "Inbox";
