import React from "react";

import styles from "./styles.modules.scss";

type ProviderIconType = {
    provider: string;
    variant: string;
};

export const ProviderIconView = ({ provider, variant }: ProviderIconType) => (
    <div
        className={
            "provider-icon " +
            `${styles.providerIcon} ` +
            `${provider} ` +
            `${variant}`
        }
    />
);
