import React, { useEffect } from "react";

import { setGameData } from "../../../contexts/game/actions/set-game-data";

import { useLocalizedNamespace } from "../../../hooks/use-localized-namespace";

import { loadUntilItSucceeds } from "../../../utils/load-until-it-succeeds";

export const withLoadedData = (Component) => (props) => {
    const { context, namespace, parser, locale } = props;

    const { lang } = locale;
    const { state, dispatch } = context;
    const { settings } = state;
    const { game, search } = settings;

    const newSearch = new URLSearchParams(search);
    if (!newSearch.has("cachebust")) {
        newSearch.set("cachebust", Date.now().toString());
    }

    const url = `./data/${lang}/${namespace}/content.json?${newSearch}`;
    const localizedNamespace = `${lang}-${namespace}`;
    const data = game[localizedNamespace];

    const [error, setError] = React.useState();

    const [, setLocalizedNamespace] = useLocalizedNamespace();

    const onError = setError;

    const onLoad = (raw) =>
        raw
            .json()
            .then((json) => {
                dispatch(setGameData(localizedNamespace, parser(json)));
            })
            .catch(onError);

    useEffect(() => {
        setLocalizedNamespace(localizedNamespace);
    }, [localizedNamespace]);

    useEffect(() => {
        setError(undefined);

        if (!data) return loadUntilItSucceeds(url, onLoad, onError);
    }, [lang]);

    return <Component {...props} data={data} error={error} />;
};
