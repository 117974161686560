import React from "react";
import { useParams } from "react-router-dom";

type ParamsWithLang = { lang: string };

export const withSelectedLocale = (Component) => (props) => {
    const { lang } = useParams<ParamsWithLang>();

    const { localization } = props;
    const { default: defaultLang, available, locales } = localization;
    const validLang = available.includes(lang) ? lang : defaultLang;
    const locale = {
        ...locales[defaultLang],
        ...locales[validLang],
        lang: validLang,
    };

    return <Component {...props} locale={locale} />;
};
