import React from "react";

import { Pressable } from "../../../pressable";
import { ContentCard } from "../../../content-card";
import { ContentCardCaption } from "../../../content-card/caption";
import { ProviderIcon } from "../../../video/provider-icon";
import { Video } from "../../../video";

import styles from "./styles.modules.scss";

type VideoArticleViewType = {
    categories?: { title: string; color: string }[];
    creator?: {
        name: string;
        icon: string;
    };
    embed: {
        id: string;
        livepid?: string;
        provider: string;
        url: string;
    };
    type: string;
    embedVideo: boolean;
    id: string;
    lang: string;
    listingSize: string;
    live: boolean;
    newUntil: number;
    postDate?: number;
    thumbnail: ImageSourceSets;
    thumbnailAlt?: ImageSourceSets;
    title: string;
    pinned?: boolean;
    small: boolean;
    creatorName?: string;
    icon?: string;
    onClick: () => void;
};

export const VideoArticleView = ({
    categories,
    creatorName,
    embed,
    embedVideo,
    icon,
    id,
    lang,
    live,
    newUntil,
    onClick,
    pinned,
    postDate,
    small,
    thumbnail,
    thumbnailAlt,
    type,
    title,
}: VideoArticleViewType) => (
    <div
        className={`
            article-video ${styles.articleVideo}
            ${small ? `small ${styles.small}` : ""}
        `}
        data-testid={type}
        onClick={onClick}
    >
        <Pressable animationSize="large">
            <div className={`card ${styles.card}`}>
                <ContentCard
                    id={id}
                    lang={lang}
                    newUntil={newUntil}
                    pinned={pinned}
                >
                    <div className={`content ${styles.content}`}>
                        <div className={`video ${styles.video}`}>
                            <Video
                                containerId={`video-news-${id}`}
                                embedVideo={embedVideo}
                                embed={embed}
                                hideFilter={small}
                                hideProvider={small}
                                live={live}
                                thumbnail={small ? thumbnailAlt : thumbnail}
                            />
                        </div>

                        <div className={`caption ${styles.caption}`}>
                            <ContentCardCaption
                                creator={creatorName}
                                cardId={id}
                                categories={categories}
                                ctaHidden={small || embedVideo}
                                external={!embedVideo}
                                icon={icon}
                                postDate={postDate}
                                title={title}
                                provider={embed.provider}
                            />
                        </div>

                        {small && (
                            <div className={`provider ${styles.provider} `}>
                                <ProviderIcon
                                    provider={embed.provider}
                                    variant="small"
                                />
                            </div>
                        )}
                    </div>
                </ContentCard>
            </div>
        </Pressable>
    </div>
);
