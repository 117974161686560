import React from "react";

import { setInboxRegionToLocalStorage } from "./region-utils";
import { createContext, useReducer } from "react";
import { useParams } from "react-router-dom";
import { EsportRegion } from "./types";

import { SET_INBOX_REGIONS } from "supercell-frontend-base/src/constants/types/commands";
import { useSendCommand } from "supercell-frontend-base/src/hooks/use-send-command";

export type EsportState = {
    selectedRegions: EsportRegion[];
    regionsAvailable: EsportRegion[];
    isRegionSelectorModalOpen: boolean;
    canChangeRegion: boolean;
};

export const DEFAULT_STATE: EsportState = {
    selectedRegions: [],
    regionsAvailable: ["APAC", "EMEA", "NA & LATAM N", "LATAM S"],
    isRegionSelectorModalOpen: false,
    canChangeRegion: true,
};

type EsportAction =
    | { type: "SET_REGION_MODAL_OPEN"; isRegionSelectorModalOpen: boolean }
    | { type: "SELECT_REGION"; region: EsportRegion }
    | { type: "UNSELECT_REGION"; region: EsportRegion };

export const EsportContext = createContext<{
    state: EsportState;
    dispatch: (action: EsportAction) => void;
}>({
    state: DEFAULT_STATE,
    dispatch: () => null,
});

export const EsportContextProvider = ({
    children,
    initialState,
}: {
    children: JSX.Element | JSX.Element[];
    initialState: EsportState;
}): JSX.Element => {
    const sendCommand = useSendCommand();
    const params = useParams();

    const setInboxRegions = (regions: EsportRegion[]) => {
        sendCommand(SET_INBOX_REGIONS, { regions });

        setInboxRegionToLocalStorage(regions, params.lang);
    };

    const reducer = (state: EsportState, action: EsportAction): EsportState => {
        switch (action.type) {
            case "SET_REGION_MODAL_OPEN": {
                // If the user has closed the modal, we also want to save the selected regions
                if (!action.isRegionSelectorModalOpen) {
                    setInboxRegions(state.selectedRegions);
                }

                return {
                    ...state,
                    isRegionSelectorModalOpen: action.isRegionSelectorModalOpen,
                };
            }
            case "SELECT_REGION": {
                if (state.selectedRegions.includes(action.region)) {
                    return state;
                }

                const newRegions = [...state.selectedRegions, action.region];

                setInboxRegions(newRegions);

                return {
                    ...state,
                    selectedRegions: newRegions,
                };
            }
            case "UNSELECT_REGION": {
                const newRegions = state.selectedRegions.filter(
                    (r) => r !== action.region
                );

                setInboxRegions(newRegions);

                return {
                    ...state,
                    selectedRegions: newRegions,
                };
            }
            default:
                throw new Error();
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <EsportContext.Provider value={{ state, dispatch }}>
            {children}
        </EsportContext.Provider>
    );
};

export const withCheckinContext = (
    initialState: EsportState,
    Component: React.FC
): ReturnType<React.FC> => {
    return (
        <EsportContextProvider initialState={initialState}>
            <Component />
        </EsportContextProvider>
    );
};
