import { GameParams } from "../validation/game-params";

const doMatch = (
    targeting: string[],
    value?: string | number | null | boolean
): boolean => {
    if (!value) {
        return true;
    }

    if (!targeting.length) {
        return true;
    }

    const asString = value.toString();

    return targeting.includes(asString);
};

export const filterEntry = (
    entry: InboxEvent | InboxArticle,
    gameParams: GameParams &
        Record<string, string | number | boolean | undefined | null>
) => {
    if (Object.keys(gameParams).length === 0) {
        return true;
    }

    if (!entry.targeting) {
        return true;
    }

    const { country, platform, th, th2 } = gameParams;

    let {
        country: entryCountry,
        platform: entryPlatform,
        townHallLevel: entryTownHallLevel,
        builderHallLevel: entryBuilderHallLevel,
    } = entry.targeting;

    entryCountry = entryCountry || [];
    entryPlatform = entryPlatform || [];
    entryTownHallLevel = entryTownHallLevel || [];
    entryBuilderHallLevel = entryBuilderHallLevel || [];

    const countryMatch = doMatch(entryCountry, country);
    const platformMatch = doMatch(entryPlatform, platform);
    const townHallMatch = doMatch(entryTownHallLevel, th);
    const builderHallMatch = doMatch(entryBuilderHallLevel, th2);

    return countryMatch && platformMatch && townHallMatch && builderHallMatch;
};
