import React from "react";
import { FormattedMessage } from "react-intl";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { Button } from "../button";

import styles from "./styles.modules.scss";

type ListViewType<T> = {
    header: JSX.Element | null;
    footer: JSX.Element | null;
    Item: (props: T) => JSX.Element;
    items: T[][] | T[];
    columns?: number;
    loadMore: () => void;
    more: boolean;
};

export const ListView = ({
    footer,
    header,
    Item,
    items,
    columns = 1,
    loadMore,
    more,
}: ListViewType<any>) => {
    return (
        <div className={`list ${styles.list}`}>
            {header && (
                <div className={`header ${styles.header}`}>{header}</div>
            )}

            <div className={`items ${styles.items}`}>
                {columns === 1 ? (
                    <TransitionGroup className={styles.itemTransition}>
                        {items.map((data) => (
                            <CSSTransition
                                key={data.id}
                                timeout={200}
                                classNames="anim-item"
                            >
                                <div className={`row ${styles.row}`}>
                                    <div
                                        className="column"
                                        style={{
                                            width: `${100 / columns}%`,
                                        }}
                                    >
                                        <div className={`item ${styles.item}`}>
                                            <Item data={data} />
                                        </div>
                                    </div>
                                </div>
                            </CSSTransition>
                        ))}
                    </TransitionGroup>
                ) : (
                    <TransitionGroup className={styles.itemTransition}>
                        {items.map((row, index) => (
                            <CSSTransition
                                key={row[0]?.id ?? index}
                                timeout={200}
                                classNames="anim-item"
                            >
                                <div
                                    key={index}
                                    className={`row ${styles.row}`}
                                >
                                    {row.map((data) => (
                                        <div
                                            key={data.id}
                                            className="column"
                                            style={{
                                                width: `${100 / columns}%`,
                                            }}
                                        >
                                            <div
                                                className={`item ${styles.item}`}
                                            >
                                                <Item data={data} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </CSSTransition>
                        ))}
                    </TransitionGroup>
                )}
            </div>

            {more && (
                <div className={`load-more ${styles.loadMore}`}>
                    <Button onClick={loadMore}>
                        <span className="buttonTextWrapper">
                            <FormattedMessage id="globalText.langLoadMore" />
                        </span>
                    </Button>
                </div>
            )}

            {!more && (
                <div className={`no-more ${styles.noMore}`}>
                    <FormattedMessage id="globalText.langOutOfFeed" />
                </div>
            )}

            <div className={`footer ${styles.footer}`}>{footer}</div>
        </div>
    );
};
