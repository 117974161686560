import { captureManualException } from "../tracing/sentry";
import { z } from "zod";

const base = `${window.POLL_API_BASE}/poll`;

if (!base) {
    captureManualException(new Error("Poll API not defined"));
}

if (!("fetch" in window)) {
    captureManualException(new Error("Fetch API not supported"));
}

const PollApiErrorResponse = z.object({
    message: z.string(),
    type: z.literal("error"),
});
type PollApiErrorResponse = z.infer<typeof PollApiErrorResponse>;

const PollApiVoteResponse = z.object({
    type: z.literal("pollVotePost"),
});
type PollApiVoteResponse = z.infer<typeof PollApiVoteResponse>;

const PollApiGetVoteResponse = z.object({
    data: z.object({
        options: z.array(z.string()),
    }),
    type: z.literal("pollVoteGet"),
});
type PollApiGetVoteResponse = z.infer<typeof PollApiGetVoteResponse>;

const PollApiGetResultsResponse = z.object({
    data: z.object({
        options: z.array(
            z.object({
                optionId: z.string(),
                votesPercentage: z.number().optional(),
            })
        ),
        poll: z.object({
            active: z.boolean(),
            resultsPublic: z.boolean(),
        }),
    }),
    type: z.literal("pollGet"),
});
type PollApiGetResultsResponse = z.infer<typeof PollApiGetResultsResponse>;

const PollApiResponse = z.union([
    PollApiErrorResponse,
    PollApiVoteResponse,
    PollApiGetVoteResponse,
    PollApiGetResultsResponse,
]);
type PollApiResponse = z.infer<typeof PollApiResponse>;

export const vote = async (
    pollId: string,
    optionId: string,
    sessionToken: string
): Promise<PollApiResponse> => {
    const params = new URLSearchParams();
    params.set("pollId", pollId);
    params.set("optionId", optionId);

    try {
        const response = await fetch(`${base}/vote/?${params}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${sessionToken}`,
            },
        });

        return PollApiResponse.parse(await response.json());
    } catch (error) {
        if (error instanceof Error && !(error instanceof TypeError)) {
            captureManualException(error);
        }
        return { type: "error", message: "Unknown error" };
    }
};

export const getVote = async (
    pollId: string,
    sessionToken: string
): Promise<string | null> => {
    try {
        const params = new URLSearchParams();
        params.set("pollId", pollId);

        const response = await fetch(`${base}/vote/?${params}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${sessionToken}`,
            },
        });

        const parsed = PollApiResponse.parse(await response.json());

        if (parsed.type !== "pollVoteGet") {
            return null;
        }

        return parsed.data.options[0] ?? null;
    } catch (error) {
        if (error instanceof Error && !(error instanceof TypeError)) {
            captureManualException(error);
        }
        return null;
    }
};

export type PollInfo = {
    results: null | number[];
    status: "open-public" | "open-hidden" | "closed";
};

export const getPollInfo = async (pollId: string): Promise<PollInfo> => {
    try {
        const params = new URLSearchParams();
        params.set("pollId", pollId);

        const response = await fetch(`${base}/?${params}`, {
            method: "GET",
        });

        const parsed = PollApiResponse.parse(await response.json());

        if (parsed.type !== "pollGet") {
            return {
                results: null,
                status: "closed",
            };
        }

        const status =
            parsed.data.poll.active && parsed.data.poll.resultsPublic
                ? "open-public"
                : parsed.data.poll.active && !parsed.data.poll.resultsPublic
                ? "open-hidden"
                : "closed";

        const results = parsed.data.poll.resultsPublic
            ? parsed.data.options
                  .sort((a, b) => (a.optionId > b.optionId ? 1 : -1))
                  .map(({ votesPercentage }) => votesPercentage ?? 0)
            : null;

        return {
            results,
            status,
        };
    } catch (error) {
        if (error instanceof Error && !(error instanceof TypeError)) {
            captureManualException(error);
        }
        return {
            results: null,
            status: "closed",
        };
    }
};
