export const getArrayInSets = <T>(array: T[], inSetsOf: number): T[][] => {
    const copyOfArray = [...array];
    const arrayInSets = [];

    while (copyOfArray.length) {
        arrayInSets.push(copyOfArray.splice(0, inSetsOf));
    }

    return arrayInSets;
};
