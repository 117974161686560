import { SET_AUTH_TOKEN, SET_GAME_DATA } from "../action-types";

import { authTokenReducer } from "./auth-token-reducer";
import { gameDataReducer } from "./game-data/game-data-reducer";

export const gameContextReducer = (state: GameState, action) => {
    switch (action.type) {
        case SET_AUTH_TOKEN:
            return authTokenReducer(state, action);
        case SET_GAME_DATA:
            return gameDataReducer(state, action);
        default:
            return state;
    }
};
