import React from "react";

import { Button } from "../../../button";
import { Image } from "../../../image";

import styles from "./styles.modules.scss";

type BlockButtonViewType = Pick<
    InboxArticleButtonBlock,
    "image" | "label" | "position" | "variant"
> & {
    onClick: () => void;
};
export const BlockButtonView = ({
    image,
    label,
    onClick,
    position,
    variant,
}: BlockButtonViewType) => (
    <div
        className={`block-button ${styles.blockButton} ${
            position !== undefined
                ? `${styles[position]} ${position}`
                : `${styles.left} left`
        }`}
    >
        <div className={`before ${styles.before}`} />

        {image ? (
            <div className={`image ${styles.image}`} onClick={onClick}>
                <Image sources={image} />
            </div>
        ) : (
            <Button onClick={onClick} variant={variant}>
                <div className={`label ${styles.label}`}>{label}</div>
            </Button>
        )}

        <div className={`after ${styles.after}`} />
    </div>
);
