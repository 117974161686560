import { withCountdown } from "../../../../hocs/with-countdown";

import { DefaultSlideController } from "./controller";

const mapTimerProperty = ({ timerTarget }: { timerTarget: number }) =>
    timerTarget;

const withMappedTimerProperty = withCountdown(mapTimerProperty);

export const DefaultSlideContainer = withMappedTimerProperty(
    DefaultSlideController
);
