import React from "react";

import { Provider } from "../../contexts/api";

export const withApiContextProvider =
    (Component: typeof React.Component) =>
    ({ apiConfig, ...props }) =>
        (
            <Provider value={apiConfig}>
                <Component {...props} />
            </Provider>
        );
