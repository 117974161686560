import React from "react";
import { FormattedMessage } from "react-intl";

import styles from "./styles.modules.scss";

export const NewLabelView = () => (
    <div className={`new-label ${styles.newLabel}`}>
        <div className={`shadows ${styles.shadows}`}>
            <div className={`south ${styles.south}`} />

            <div className={`east ${styles.east}`} />
        </div>

        <div className={`content ${styles.content}`}>
            <div className={`start ${styles.start}`}>
                <div className={`top ${styles.top}`} />

                <div className={`middle ${styles.middle}`} />

                <div className={`bottom ${styles.bottom}`} />
            </div>

            <div className={`center ${styles.center}`}>
                <div className={`top ${styles.top}`} />

                <div className={`middle ${styles.middle}`}>
                    <FormattedMessage id="globalText.langNew" />
                </div>

                <div className={`bottom ${styles.bottom}`} />
            </div>

            <div className={`end ${styles.end}`}>
                <div className={`top ${styles.top}`} />

                <div className={`middle ${styles.middle}`} />

                <div className={`bottom ${styles.bottom}`} />
            </div>
        </div>
    </div>
);
