import React, { createContext, useReducer, useEffect } from "react";
import {
    parseGameParams,
    type Parseable,
    GameParams,
} from "../validation/game-params";
import { getAvatarId } from "../utils/jwt";
import {
    setUser,
    setGameParams,
    captureManualException,
} from "../tracing/sentry";

type EmptyGameParams = Record<string, never>;

export type GameParamsState = {
    gameParams: GameParams | EmptyGameParams;
};

export const DEFAULT_STATE: GameParamsState = {
    gameParams: {},
};

type GameParamsAction = {
    type: "SET_GAME_PARAMS";
    gameParams: GameParams;
};

export const GameParamsContext = createContext<GameParamsState>(DEFAULT_STATE);

export const GameParamsContextProvider = <T extends GameParams>({
    children,
    initialState = DEFAULT_STATE,
    gameParamsParser,
}: {
    children: JSX.Element | JSX.Element[];
    initialState: GameParamsState;
    gameParamsParser: Parseable<T>;
}): JSX.Element => {
    const reducer = (
        state: GameParamsState,
        action: GameParamsAction
    ): GameParamsState => {
        switch (action.type) {
            case "SET_GAME_PARAMS": {
                return {
                    ...state,
                    gameParams: action.gameParams,
                };
            }
            default:
                throw new Error();
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        window["setParams"] = (unvalidatedInboxGameParams: any) => {
            console.log("setParams", unvalidatedInboxGameParams);

            const { output: gameParams, validationError } = parseGameParams<T>(
                unvalidatedInboxGameParams,
                gameParamsParser
            );

            const isMaintenanceMode = Boolean(
                unvalidatedInboxGameParams.maintenance
            );

            if (window.IS_DEV && validationError && !isMaintenanceMode) {
                throw validationError;
            }

            if (!window.IS_DEV && validationError && !isMaintenanceMode) {
                captureManualException(validationError);
            }

            const { jwt } = gameParams;

            if (!jwt && !isMaintenanceMode) {
                return window.sendCommand?.("tokenExpired");
            }

            if (jwt && window.SENTRY_DSN) {
                const avatarId = getAvatarId(jwt);
                if (avatarId) {
                    setUser(avatarId);
                }
            }

            if (gameParams && window.SENTRY_DSN) {
                setGameParams(gameParams);
            }

            const { platform: cd1, xp: cd2 } = gameParams;

            window.trackEvent?.({ cd1, cd2 });

            dispatch({
                type: "SET_GAME_PARAMS",
                gameParams: gameParams,
            });
        };

        window.sendCommand?.("getParams");
    }, []);

    return (
        <GameParamsContext.Provider value={state}>
            {children}
        </GameParamsContext.Provider>
    );
};

export const useGameParamsContext = <T extends GameParams>() => {
    const ctx = React.useContext(GameParamsContext);

    return ctx as {
        gameParams: T;
    };
};
