import React, { useContext } from "react";
import { EsportContext } from "../store";
import { regionLabels } from "../util";
import styles from "./styles.modules.scss";

export const EsportSelectPrompt = (props: {
    globals: Record<string, string>;
}) => {
    const { globals } = props;
    const { state, dispatch } = useContext(EsportContext);
    const { selectedRegions, canChangeRegion } = state;

    const regionList = selectedRegions.map((r) => regionLabels[r]);

    return (
        <div className={styles["esport-prompt"]}>
            <h2>
                {`${globals["globalText.esportFollowing"]} ${regionList
                    .join(", ")
                    .toUpperCase()}`}
            </h2>

            {canChangeRegion && (
                <>
                    <span className={styles.separator} />

                    <span
                        className={styles["esport-prompt-link"]}
                        onClick={() =>
                            dispatch({
                                type: "SET_REGION_MODAL_OPEN",
                                isRegionSelectorModalOpen: true,
                            })
                        }
                    >
                        {globals["globalText.esportChangeRegion"]}
                    </span>
                </>
            )}
        </div>
    );
};
