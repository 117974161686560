import React from "react";

import { Pressable } from "../../../pressable";

import styles from "./styles.modules.scss";

export const HomeCtaView = ({ onClick }: { onClick: () => void }) => (
    <div className={`home-cta ${styles.homeCta}`} onClick={onClick}>
        <Pressable>
            <div className={`icon ${styles.icon}`} />
        </Pressable>
    </div>
);
