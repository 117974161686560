import React from "react";
import { GameParams } from "../../../validation/game-params";
import { createIntl } from "react-intl";

import { generateLocaleStyle } from "../../../utils/generate-locale-style";
import { parseLang } from "../../../parsers/lang";

import { TabView } from "./view";

export const TabController = ({
    children,
    data,
    locale,
    userSettings,
}: {
    children: React.ReactChildren;
    data: unknown;
    locale: Locale;
    userSettings: GameParams;
}) => {
    const style = generateLocaleStyle(locale);

    const { globals } = data;

    const intl = createIntl({
        locale: parseLang(locale.lang),
        messages: globals as Record<string, string>,
    });

    const props = {
        children,
        data,
        intl,
        locale,
        style,
        userSettings,
    };

    return <TabView {...props} />;
};
