import { parseBlock } from "./block";

export const parseDetails = (details: {
    [key in string]: any;
}): InboxArticleBlock[] =>
    Object.keys(details)
        .map((key) => ({
            ...details[key],
            id: key,
        }))
        .map(parseBlock)
        .sort(({ order: a }, { order: b }) => parseInt(a) - parseInt(b));
