import React from "react";

import { ContentCardCaption } from "../../../content-card/caption";
import { ContentCard } from "../../../content-card";

import { Video } from "../../../video";
import { ProviderIcon } from "../../../video/provider-icon";

import styles from "./styles.modules.scss";

type BlockVideoViewType = Pick<
    InboxArticleVideoBlock,
    "embed" | "embedVideo" | "id" | "live" | "thumbnail"
> & {
    onClick: () => void;
    lang: string;

    caption?: {
        author?: string;
        title?: string;
        icon?: string;
    };
};

export const BlockVideoView = ({
    caption,
    embed,
    embedVideo,
    id,
    lang,
    live,
    onClick,
    thumbnail,
}: BlockVideoViewType) => (
    <div className={`block-video ${styles.blockVideo}`} onClick={onClick}>
        <div className={`card ${styles.card}`}>
            <ContentCard id={id} lang={lang}>
                <div className={`content ${styles.content}`}>
                    <div className={`video ${styles.video}`}>
                        <Video
                            caption={caption}
                            containerId={`video-block-${id}`}
                            embedVideo={embedVideo}
                            embed={embed}
                            live={live}
                            thumbnail={thumbnail}
                        />
                    </div>

                    <div className={`caption ${styles.caption}`}>
                        {caption && (
                            <ContentCardCaption
                                creator={caption.author}
                                cardId={id}
                                ctaHidden={true}
                                icon={caption.icon}
                                title={caption.title}
                            />
                        )}

                        {embed.provider && (
                            <div className={`provider ${styles.provider} `}>
                                <ProviderIcon
                                    provider={embed.provider}
                                    variant="small"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </ContentCard>
        </div>
    </div>
);
