import React, { SyntheticEvent } from "react";

import { Loader as DefaultLoader } from "../loader";

import styles from "./styles.modules.scss";

const addFormatToSources = (
    format: string,
    sources: ImageSourceSets
): ImageSourceSets => {
    return Object.fromEntries(
        Object.entries(sources).map(([key, value]) => {
            const { path } = value;

            // Contentful resizing of SVGs is really blurry and crappy, so we'll skip over them
            if (path?.includes(".svg")) {
                return [key, value];
            }

            const withFm = path?.includes("?")
                ? `${path}&fm=${format}`
                : `${path}?fm=${format}`;

            return [key, { ...value, path: withFm }];
        })
    ) as ImageSourceSets;
};

const SourceSet = ({
    referenceWidth,
    sources,
    type,
}: {
    referenceWidth: number;
    sources: ImageSourceSets;
    type?: string;
}) => {
    const { small, smallRetina, medium, mediumRetina, large, largeRetina } =
        sources;

    const largeMediaQuery = `(min-width: ${Math.round(
        referenceWidth * 0.8
    )}px)`;
    const mediumMediaQuery = `(min-width: ${Math.round(
        referenceWidth * 0.4
    )}px)`;

    return (
        <>
            <source
                srcSet={`${large.path} 1x, ${largeRetina.path} 2x`}
                media={largeMediaQuery}
                type={type}
            />

            <source
                srcSet={`${medium.path} 1x, ${mediumRetina.path} 2x`}
                media={mediumMediaQuery}
                type={type}
            />

            <source
                srcSet={`${small.path} 1x, ${smallRetina.path} 2x`}
                type={type}
            />
        </>
    );
};

const isRunningContentful = !!window.RUNNING_CONTENTFUL;

export const ImageView = ({
    sources,
    loaded,
    onLoad,
    alt,
    cover,
    background,
    referenceWidth,
    Loader,
}: {
    sources: ImageSourceSets;
    loaded: boolean;
    alt?: string;
    cover?: boolean;
    onLoad: (e: SyntheticEvent<HTMLImageElement>) => void;
    background?: string;
    referenceWidth?: number;
    Loader?: JSX.Element | null;
}) => {
    const sourcesWithWebp = isRunningContentful
        ? addFormatToSources("webp", sources)
        : undefined;

    const sourcesWithPng = isRunningContentful
        ? addFormatToSources("png", sources)
        : undefined;

    const { small } = sources;

    return (
        <div
            className={`image ${styles.image}
            ${loaded ? `is-loaded ${styles.isLoaded}` : ""}
            ${cover ? `cover ${styles.cover}` : ""}`}
        >
            <div className={`container ${styles.container}`}>
                {Loader === null ||
                    (!loaded && (
                        <div
                            className={`loader-container ${styles.loaderContainer}`}
                        >
                            {Loader || <DefaultLoader />}
                        </div>
                    ))}

                <picture
                    className={`picture ${styles.picture}`}
                    {...(background && {
                        style: { backgroundImage: `url(${background})` },
                    })}
                >
                    {sourcesWithWebp && (
                        <SourceSet
                            sources={sourcesWithWebp}
                            referenceWidth={referenceWidth}
                            type="image/webp"
                        />
                    )}

                    <SourceSet
                        sources={sources}
                        referenceWidth={referenceWidth}
                    />

                    {sourcesWithPng && (
                        <SourceSet
                            sources={sourcesWithPng}
                            referenceWidth={referenceWidth}
                        />
                    )}

                    <img
                        alt={alt}
                        className={`img ${styles.img}`}
                        draggable={false}
                        height={small.height}
                        onLoad={onLoad}
                        src={small.path}
                        width={small.width}
                    />
                </picture>
            </div>
        </div>
    );
};
