import React from "react";
import { RegularArticleType } from "./type";
import {
    OPEN_LINK,
    TYPE_ARTICLE_ESPORT_NEWS_REGULAR,
} from "../../../../constants/types";

import { useSendCommand } from "../../../../hooks/use-send-command";
import { useOpenNewsArticle } from "../../../../hooks/use-open-news-article";

import { RegularView } from "./view";

export const RegularController = (props: RegularArticleType) => {
    const sendCommand = useSendCommand();
    const openNewsArticle = useOpenNewsArticle();

    const {
        author,
        categories,
        id,
        external,
        lang,
        like,
        listingSize,
        newUntil,
        pinned,
        postDate,
        thumbnail,
        thumbnailAlt,
        title,
        url,
        ctaHidden,
        type,
    } = props;

    const hasOnClick = !!(url || !external);

    const onClick = () => {
        if (!hasOnClick) {
            return;
        }

        external
            ? sendCommand(OPEN_LINK, url)
            : openNewsArticle(
                  id,
                  title,
                  type === TYPE_ARTICLE_ESPORT_NEWS_REGULAR ? "esport" : "news"
              );
    };

    const propsToPass = {
        author,
        categories,
        id,
        external,
        lang,
        like,
        listingSize,
        newUntil,
        onClick,
        pinned,
        postDate,
        thumbnail,
        thumbnailAlt,
        title,
        type,
        ctaHidden,
        hasOnClick,
    };

    return <RegularView {...propsToPass} />;
};
