import React from "react";

import styles from "./styles.modules.scss";

type CategoriesViewType = {
    cardId: string | number;
    categories: { title: string; color: string }[];
};

export const CategoriesView = ({ cardId, categories }: CategoriesViewType) =>
    categories && categories.length ? (
        <div className={`categories ${styles.categories}`}>
            {categories.map(({ title, color }) => (
                <div
                    key={`${cardId}-${title}`}
                    className={`category ${styles.category}`}
                    style={{ color }}
                >
                    {title}
                </div>
            ))}
        </div>
    ) : null;
