import React from "react";

import { DefaultSlideContainer } from "./container";

type DefaultSlideType = (props: {
    background?: ImageSourceSets;
    ctas: {
        label: string;
        url: string;
        type: string;
        color?: string;
    }[];
    displayTimer: boolean;
    isNew?: boolean;
    timerKind?: InboxEventTimerKind;
    timerLabel?: string;
    timerTarget?: number;
    title: string;
    type: string;
}) => JSX.Element;

export const DefaultSlide: DefaultSlideType = DefaultSlideContainer;

(DefaultSlide as React.FC)["displayName"] = "DefaultSlide";
