import React, { useState } from "react";
import { useSendCommand } from "supercell-frontend-base/src/hooks/use-send-command";
import { OPEN_LINK } from "supercell-frontend-base/src/constants/types/commands";
import { Image } from "supercell-frontend-base/src/components/image";
import { formatTime } from "supercell-frontend-base/src/utils/format-time";

import styles from "./styles.modules.scss";
import { LiveSlide } from "supercell-frontend-base/src/components/esport/types";

type EsportCarouselProps = {
    slides: LiveSlide[];
    currentTime: Date;
};

const IncomingLiveEventsBannerView = (props: EsportCarouselProps) => {
    const { slides, currentTime } = props;

    const sendCommand = useSendCommand();
    const onLinkClick = (url: string) => sendCommand(OPEN_LINK, url);

    const [selectedItemIdx, setSelectedItemIdx] = useState(0);

    const event = slides[selectedItemIdx];

    const timestamp = new Date(
        event?.livestreamStartTime ?? Date.now()
    ).getTime();

    const timeLeft = timestamp - currentTime.getTime();
    const leftDisabled = selectedItemIdx === 0;
    const rightDisabled = slides.length - 1 === selectedItemIdx;

    const onlyOneSlide = slides.length === 1;

    const onLeftClick = () => {
        if (!leftDisabled) {
            setSelectedItemIdx(selectedItemIdx - 1);
        }
    };

    const onRightClick = () => {
        if (!rightDisabled) {
            setSelectedItemIdx(selectedItemIdx + 1);
        }
    };

    return (
        <>
            <div
                className={`incoming-live-event-banner ${styles["incoming-live-event-banner"]}`}
            >
                <div
                    className={styles["banner-pagination"]}
                    style={{ opacity: onlyOneSlide ? "0" : "1" }}
                >
                    <div
                        className={styles["banner-pagination-nav-item"]}
                        onClick={onLeftClick}
                    >
                        <div
                            className={` ${
                                leftDisabled ? styles.disabled : ""
                            } ${styles["banner-pagination-nav-icon"]} ${
                                styles["left"]
                            }`}
                        />
                    </div>

                    <div
                        className={styles["banner-pagination-nav-item"]}
                        onClick={onRightClick}
                    >
                        <div
                            className={` ${
                                rightDisabled ? styles.disabled : ""
                            } ${styles["banner-pagination-nav-icon"]} ${
                                styles["right"]
                            }`}
                        />
                    </div>
                </div>

                <div className={styles["banner-middle"]}>
                    {event?.eventPromotionLogo && (
                        <Image sources={event?.eventPromotionLogo} />
                    )}

                    <h3 className={styles["promotion-name"]}>
                        {event?.eventPromotionName}
                    </h3>

                    <span className={styles.divider} />

                    <span className={styles.time}>
                        {formatTime(Math.floor(timeLeft / 1000))}
                    </span>
                </div>

                <div
                    className={styles.subscribe}
                    onClick={() =>
                        onLinkClick(event?.subscribeButton?.url ?? "")
                    }
                >
                    {event?.subscribeButton?.label}
                </div>
            </div>
        </>
    );
};

export { IncomingLiveEventsBannerView };
