import { parseCategories } from "../categories";
import { parseLang } from "../lang";
import { parseTimestamp } from "../timestamp";
import { parseArticleType } from "../type";
import { parseCreator } from "./creator";
import { parseDetails } from "./details";

const emptyImage = {
    small: {},
    smallRetina: {},
    medium: {},
    mediumRetina: {},
    large: {},
    largeRetina: {},
};

export const parseArticle = (
    article: any
): InboxArticle | InboxEsportArticle => {
    const type = parseArticleType(article.type);

    if (type === "TYPE_ESPORT_REWATCH_MATCH_ENTRY") {
        const out: InboxEsportRewatchMatchArticle = {
            id: article.id,
            type: "TYPE_ESPORT_REWATCH_MATCH_ENTRY",
            postDate: parseTimestamp(article.postDate),
            lang: parseLang(article.lang),
            section: "esportNewsEntries",
            title: "",

            playerOneName: article.playerOneName,
            playerOneScore: article.playerOneScore,
            playerOneImage: article.playerOneImage,

            playerTwoName: article.playerTwoName,
            playerTwoScore: article.playerTwoScore,
            playerTwoImage: article.playerTwoImage,

            matchThumbnail: article.matchThumbnail || emptyImage,
            matchUrl: article.matchUrl,
            provider: article.provider,
        };

        return out;
    }

    const {
        author,
        categories,
        creator,
        details,
        detailsTitle,
        embed,
        embedVideo,
        entryFeatured,
        external,
        heroImage,
        heroSurTitle,
        hideTitle,
        id,
        lang,
        listingSize,
        livestreamEndDate,
        livestreamStartDate,
        newUntil,
        pinned,
        postDate,
        targeting,
        thumbnail,
        thumbnailAlt,
        title,
        url,
        esportRegions,
    } = article;
    return {
        author,
        categories: parseCategories(categories || []),
        creator: creator && parseCreator(creator),
        details: details && parseDetails(details),
        detailsTitle,
        embed,
        embedVideo,
        entryFeatured,
        external,
        heroImage,
        heroSurTitle,
        hideTitle,
        id,
        lang: parseLang(lang),
        listingSize,
        livestreamEndDate: livestreamEndDate
            ? parseTimestamp(livestreamEndDate)
            : null,
        livestreamStartDate: livestreamStartDate
            ? parseTimestamp(livestreamStartDate)
            : null,
        newUntil: parseTimestamp(newUntil),
        pinned,
        postDate: parseTimestamp(postDate),
        targeting,
        thumbnail: thumbnail || emptyImage,
        thumbnailAlt: thumbnailAlt || emptyImage,
        title,
        type,
        url,
        esportRegions,
    };
};
