import React from "react";

import { useIntl } from "react-intl";

import { OPEN_HOME } from "../../../../constants/types";
import { useSendCommand } from "../../../../hooks/use-send-command";

import { HomeCtaView } from "./view";

export const HomeCtaController = () => {
    const { locale } = useIntl();
    const sendCommand = useSendCommand();

    const handleClick = () => {
        sendCommand(OPEN_HOME, locale);
    };

    return <HomeCtaView onClick={handleClick} />;
};
