import { copyObject } from "../utils/copy-object";

import { parseArticle } from "./articles";
import { parseSocialLinks } from "./social-links";

export const esportParser = (data: any) => {
    data = copyObject(data);

    const { entries, globals } = data as {
        entries: any;
        globals: Globals;
    };

    const articles: InboxEsportArticle[] = (
        entries.esportNewsEntries || []
    ).map((article: any) => parseArticle(article));

    const socialLinks = parseSocialLinks(data.socialLinks);

    return {
        globals,
        socialLinks,
        articles,
        carouselItems: entries.esportCarouselEntries.map((e) => ({
            ...e,
            liveLabel: globals["globalText.esportLive"],
        })),
    };
};
