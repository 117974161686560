import React from "react";

import styles from "./styles.modules.scss";

import { Button } from "../button";
import { useNavigate, useParams } from "react-router-dom";
import { generateLocaleStyle } from "../../utils/generate-locale-style";

type ErrorViewProps = {
    locale: Locale;
};

export const ErrorBoundaryView = (props: ErrorViewProps) => {
    const { locale } = props;
    const navigate = useNavigate();
    const params = useParams();
    const style = locale ? generateLocaleStyle(locale) : {};

    const route = `/${params.lang ?? ""}`;

    return (
        <div className={`error ${styles.error}`} style={style}>
            <div className={`title ${styles.title}`}>Sorry!</div>

            <div className={`detail ${styles.detail}`}>
                Something did not quite work as expected...
            </div>

            <Button animationSize="large" onClick={() => navigate(route)}>
                <span className="buttonTextWrapper">BACK TO NEWS</span>
            </Button>
        </div>
    );
};
