export const parseStatsTable = (lines) =>
    lines
        .reduce(
            (list, { column1, column2, column3 }) => [
                [...list[0], column1],
                [...list[1], column2],
                [...list[2], column3],
            ],
            [[], [], []]
        )
        .map(([header, value, unit]) => ({ header, value, unit }));
