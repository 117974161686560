import cn from "classnames";
import React, { useState } from "react";
import styles from "./styles.modules.scss";
import { ContentCard } from "../../../content-card";
import { Image } from "../../../image";
import { Pressable } from "../../../pressable";

import { OPEN_LINK } from "../../../../constants/types";
import { useSendCommand } from "../../../../hooks/use-send-command";
import { useElementAppearsOnScreen } from "../../../../hooks/use-element-on-screen";

const RevealSeparatorSVG = (props: { leftFill: string; rightFill: string }) => {
    const { leftFill, rightFill } = props;
    return (
        <svg
            className={cn(styles.angle)}
            viewBox="0 0 10 37"
            width="11%"
            height="100%"
            preserveAspectRatio="xMinYMin meet"
        >
            <path d="M0,0h10L0,37v0z" fill={leftFill}></path>

            <path d="M10,0V37H0z" fill={rightFill}></path>
        </svg>
    );
};

const RevealScoreButton = (props: {
    playerOneScore?: number;
    playerTwoScore?: number;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}) => {
    const { playerOneScore, playerTwoScore, isOpen, setIsOpen } = props;

    if (playerOneScore === undefined || playerTwoScore === undefined) {
        return null;
    }

    const isTie = playerOneScore === playerTwoScore;
    const didPlayerOneWin = playerOneScore > playerTwoScore;

    const leftFill = isTie
        ? "#0062b1"
        : didPlayerOneWin
        ? "#0062b1"
        : "#e5e6ec";

    const rightFill = isTie
        ? "#DC293F"
        : !didPlayerOneWin
        ? "#DC293F"
        : "#e5e6ec";

    const content = isOpen ? (
        <span className={cn(styles.scores)}>
            <div
                className={cn(
                    "score",
                    styles.score,
                    "score-left",
                    styles["score-left"],
                    {
                        [styles.winner]: didPlayerOneWin || isTie,
                        winner: didPlayerOneWin || isTie,
                        loser: !didPlayerOneWin,
                    }
                )}
            >
                {playerOneScore}
            </div>

            <RevealSeparatorSVG leftFill={leftFill} rightFill={rightFill} />

            <div
                className={cn(
                    "score",
                    styles.score,
                    "score-right",
                    styles["score-right"],
                    {
                        [styles.winner]: !didPlayerOneWin || isTie,
                        winner: !didPlayerOneWin || isTie,
                        loser: didPlayerOneWin,
                    }
                )}
            >
                {playerTwoScore}
            </div>
        </span>
    ) : (
        // TODO: Translations here
        <span>Reveal Score</span>
    );

    return (
        <div>
            <Pressable animationSize="large">
                <div
                    onClick={(event) => {
                        setIsOpen(true);
                        event.stopPropagation();
                    }}
                    className={cn(
                        "reveal-score-button",
                        styles["reveal-score-button"],
                        {
                            revelead: isOpen,
                        }
                    )}
                >
                    {content}
                </div>
            </Pressable>
        </div>
    );
};

export const RewatchMatchEntryView = (
    props: InboxEsportRewatchMatchArticle
) => {
    const {
        id,
        lang,
        matchThumbnail,

        type,

        playerOneImage,
        playerTwoImage,

        playerOneName,
        playerTwoName,

        playerOneScore,
        playerTwoScore,

        matchUrl,

        provider,
    } = props;

    const [isScoreRevealed, setIsScoreRevealed] = useState(false);
    const sendCommand = useSendCommand();
    const [videoIconRef, videoIconIsVisible] =
        useElementAppearsOnScreen<HTMLDivElement>();

    const openMatchLink = () => {
        if (!matchUrl) {
            return;
        }

        sendCommand(OPEN_LINK, matchUrl);
    };

    const showVideoIcon = matchUrl && matchUrl.length > 0;

    return (
        <div
            className={cn("rewatch-match", styles["rewatch-match"])}
            data-testid={type}
        >
            <Pressable animationSize="large">
                <div className={`card ${styles.card}`} onClick={openMatchLink}>
                    <ContentCard id={id} lang={lang}>
                        <div className={`content ${styles.content}`}>
                            <div className={cn(styles["img-wrapper"])}>
                                <div
                                    className={cn(
                                        "thumbnail",
                                        styles.thumbnail
                                    )}
                                >
                                    <Image sources={matchThumbnail} />
                                </div>

                                {showVideoIcon && (
                                    <div
                                        ref={videoIconRef}
                                        className={cn(
                                            "video-icon",
                                            "video-icon-" + provider,
                                            styles["video-icon"],
                                            styles["video-icon-" + provider],
                                            {
                                                visible: videoIconIsVisible,
                                            }
                                        )}
                                    />
                                )}

                                {provider && (
                                    <div
                                        className={cn(
                                            "provider",
                                            styles["provider"],
                                            styles["provider-" + provider]
                                        )}
                                    >
                                        {" "}
                                    </div>
                                )}

                                {playerOneImage && (
                                    <div
                                        className={cn(
                                            "player-image",
                                            "player-image-left",
                                            styles["player-image"],
                                            styles["player-image-left"]
                                        )}
                                    >
                                        <Image sources={playerOneImage} />
                                    </div>
                                )}

                                {playerTwoImage && (
                                    <div
                                        className={cn(
                                            "player-image",
                                            "player-image-right",
                                            styles["player-image"],
                                            styles["player-image-right"]
                                        )}
                                    >
                                        <Image sources={playerTwoImage} />
                                    </div>
                                )}

                                <span
                                    className={cn(
                                        "versus-label",
                                        styles["versus-label"]
                                    )}
                                >
                                    VS
                                </span>
                            </div>

                            <div className={cn("caption", styles.caption)}>
                                <span
                                    className={cn(
                                        "player-name",
                                        "player-name-left",
                                        styles["player-name"],
                                        {
                                            winner:
                                                playerOneScore &&
                                                playerTwoScore &&
                                                isScoreRevealed &&
                                                playerOneScore > playerTwoScore,
                                            loser:
                                                playerOneScore &&
                                                playerTwoScore &&
                                                isScoreRevealed &&
                                                playerOneScore < playerTwoScore,
                                        }
                                    )}
                                >
                                    {playerOneName}
                                </span>

                                <RevealScoreButton
                                    playerOneScore={playerOneScore}
                                    playerTwoScore={playerTwoScore}
                                    isOpen={isScoreRevealed}
                                    setIsOpen={setIsScoreRevealed}
                                />

                                <span
                                    className={cn(
                                        "player-name",
                                        "player-name-right",
                                        styles["player-name"],
                                        {
                                            winner:
                                                playerOneScore &&
                                                playerTwoScore &&
                                                isScoreRevealed &&
                                                playerOneScore < playerTwoScore,
                                            loser:
                                                playerOneScore &&
                                                playerTwoScore &&
                                                isScoreRevealed &&
                                                playerOneScore > playerTwoScore,
                                        }
                                    )}
                                >
                                    {playerTwoName}
                                </span>
                            </div>
                        </div>
                    </ContentCard>
                </div>
            </Pressable>
        </div>
    );
};
