import React from "react";

export const LabelView = ({
    label,
    Component,
    componentProps,
}: {
    label?: string;
    Component: (props: unknown) => JSX.Element;
    componentProps: Record<string, unknown>;
}): JSX.Element | null => {
    if (!label && !Component) return null;

    if (label) {
        return <span>{label}</span>;
    }

    return <Component {...componentProps} />;
};
