export const AUDIO_RESUME = "AUDIO_RESUME";
export const AUDIO_PAUSE = "AUDIO_PAUSE";
export const GAME_COMMAND = "GAME_COMMAND";
export const OPEN_HOME = "OPEN_HOME";
export const OPEN_LINK = "OPEN_LINK";
export const OPEN_NEWS_ARTICLE = "OPEN_NEWS_ARTICLE";
export const OPEN_ESPORT_ARTICLE = "OPEN_ESPORT_ARTICLE";
export const PLAY_SOUND = "PLAY_SOUND";
export const REFRESH_JWT = "REFRESH_JWT";
export const SHOW_BACK_BUTTON = "SHOW_BACK_BUTTON";
export const TRACK_EVENT = "TRACK_EVENT";
export const SET_INBOX_REGIONS = "SET_INBOX_REGIONS";
