import React from "react";
import { useIntl } from "react-intl";

import { OPEN_LINK } from "../../../../constants/types";
import { useSendCommand } from "../../../../hooks/use-send-command";

import { BlockImageView } from "./view";

const getCaption = (author?: string, title?: string) => {
    if (!author && !title) {
        return;
    }

    return {
        author,
        title,
    };
};

export const BlockImageController = (props: InboxArticleImageBlock) => {
    const sendCommand = useSendCommand();

    const { locale: lang } = useIntl();

    const { author, image, imageUrl, title } = props;

    const onClick = () => imageUrl && sendCommand(OPEN_LINK, imageUrl);

    return (
        <BlockImageView
            {...props}
            caption={getCaption(author, title)}
            image={image}
            lang={lang}
            onClick={onClick}
        />
    );
};
