import React from "react";

import { ContentCardCaptionView } from "./view";

type ContentCardCaptionType = {
    author?: string;
    creator?: string;
    cardId: string | number;
    categories?: { title: string; color: string }[];
    ctaHidden?: boolean;
    external?: boolean;
    icon?: string;
    postDate?: number;
    title?: string;
    provider?: string;
};

export const ContentCardCaptionController = (props: ContentCardCaptionType) => {
    const weekLong = 7 * 24 * 60 * 60 * 1000;

    return <ContentCardCaptionView {...props} limit={weekLong} />;
};
