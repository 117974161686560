import React from "react";

import { withSentryTracing } from "supercell-frontend-base/src/tracing/sentry";
import { AppController } from "./controller";

import { BrawlStarsGameParams } from "../../validation/game-params";
import {
    GameParamsContextProvider,
    DEFAULT_STATE,
} from "supercell-frontend-base/src/stores/gameParams";

const WithGameParams = (props) => {
    return (
        <GameParamsContextProvider<BrawlStarsGameParams>
            initialState={DEFAULT_STATE}
            gameParamsParser={BrawlStarsGameParams}
        >
            <AppController {...props} />
        </GameParamsContextProvider>
    );
};

export const App = withSentryTracing(WithGameParams);
