export const TYPE_ARTICLE_BODY_BUTTON = "TYPE_ARTICLE_BODY_BUTTON";
export const TYPE_ARTICLE_BODY_FEATURE = "TYPE_ARTICLE_BODY_FEATURE";
export const TYPE_ARTICLE_BODY_IMAGE = "TYPE_ARTICLE_BODY_IMAGE";
export const TYPE_ARTICLE_BODY_TEXT = "TYPE_ARTICLE_BODY_TEXT";
export const TYPE_ARTICLE_BODY_VIDEO = "TYPE_ARTICLE_BODY_VIDEO";
export const TYPE_ESPORT_REWATCH_MATCH_ENTRY =
    "TYPE_ESPORT_REWATCH_MATCH_ENTRY";

export const TYPE_ARTICLE_COMMUNITY_LIVE_STREAM =
    "TYPE_ARTICLE_COMMUNITY_LIVE_STREAM";
export const TYPE_ARTICLE_COMMUNITY_VIDEO = "TYPE_ARTICLE_COMMUNITY_VIDEO";

export const TYPE_ARTICLE_NEWS_EXTERNAL = "TYPE_ARTICLE_NEWS_EXTERNAL";

export const TYPE_ARTICLE_NEWS_IMAGE_SQUARE = "TYPE_ARTICLE_NEWS_IMAGE_SQUARE";
export const TYPE_ARTICLE_NEWS_IMAGE_LANDSCAPE =
    "TYPE_ARTICLE_NEWS_IMAGE_LANDSCAPE";

export const TYPE_ARTICLE_NEWS_IMAGE = "TYPE_ARTICLE_NEWS_IMAGE";
export const TYPE_ARTICLE_NEWS_REGULAR = "TYPE_ARTICLE_NEWS_REGULAR";
export const TYPE_ARTICLE_ESPORT_NEWS_REGULAR =
    "TYPE_ARTICLE_ESPORT_NEWS_REGULAR";
export const TYPE_ARTICLE_NEWS_VIDEO = "TYPE_ARTICLE_NEWS_VIDEO";

export type ARTICLE_TYPE =
    | typeof TYPE_ARTICLE_COMMUNITY_LIVE_STREAM
    | typeof TYPE_ARTICLE_COMMUNITY_VIDEO
    | typeof TYPE_ARTICLE_NEWS_EXTERNAL
    | typeof TYPE_ARTICLE_NEWS_IMAGE
    | typeof TYPE_ARTICLE_NEWS_REGULAR
    | typeof TYPE_ARTICLE_ESPORT_NEWS_REGULAR
    | typeof TYPE_ESPORT_REWATCH_MATCH_ENTRY
    | typeof TYPE_ARTICLE_NEWS_VIDEO;

export type ARTICLE_BLOCK_TYPE =
    | typeof TYPE_ARTICLE_BODY_BUTTON
    | typeof TYPE_ARTICLE_BODY_FEATURE
    | typeof TYPE_ARTICLE_BODY_IMAGE
    | typeof TYPE_ARTICLE_BODY_TEXT
    | typeof TYPE_ARTICLE_BODY_VIDEO;
