import React from "react";
import { EsportCarouselProps } from "./esport-props";
import { useTime } from "../../../hooks/use-time";

const EsportCarouselController = (props: EsportCarouselProps) => {
    const currentDate = useTime();

    const { view: EsportCarouselControllerView } = props;

    if (!props.slides || props.slides.length === 0) {
        return null;
    }

    const slides = props.slides.map((s, ind) => {
        return {
            ...s,
            id: ind,
        };
    });

    const slidesWithoutExpiredLives = slides.filter((s) => {
        if (s.type !== "liveEntry") {
            return true;
        }

        return new Date(s.livestreamEndTime ?? Date.now()) > currentDate;
    });

    const liveSlidesFirst = slidesWithoutExpiredLives.sort((a) => {
        if (a.type === "eventEntry") {
            return 1;
        }

        if (a.type === "liveEntry") {
            const isLive =
                currentDate >= new Date(a.livestreamStartTime ?? currentDate) &&
                currentDate <= new Date(a.livestreamEndTime ?? currentDate);

            if (isLive) {
                return -1;
            }
        }

        return 0;
    });

    return <EsportCarouselControllerView {...props} slides={liveSlidesFirst} />;
};

export { EsportCarouselController };
