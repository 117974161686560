import React, { useState, SyntheticEvent } from "react";

import { ImageView } from "./view";
import { useReferenceWidth } from "../../hocs/with-reference-width";

type ImageType = {
    sources: ImageSourceSets;
    cover?: boolean;
    alt?: string;
    Loader?: JSX.Element | null;
};

export const ImageController = (props: ImageType) => {
    const [loadedSrc, setLoadedSrc] = useState("");
    const referenceWidth = useReferenceWidth();

    const handleLoad = (event: SyntheticEvent<HTMLImageElement>) => {
        setLoadedSrc(event.currentTarget.currentSrc);
    };

    return (
        <ImageView
            {...props}
            loaded={loadedSrc.length > 0}
            onLoad={handleLoad}
            background={props.cover ? loadedSrc : undefined}
            referenceWidth={referenceWidth}
        />
    );
};
