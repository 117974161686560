import React from "react";

import styles from "./styles.modules.scss";

type HtmlType = { html: string; style: { direction: string } };

export const HtmlView = ({ html, style: { direction } }: HtmlType) => (
    <div
        className={`html ${styles.html} ${direction} ${
            direction === "rtl" ? styles.rtl : ""
        }`}
        dangerouslySetInnerHTML={{
            __html: html,
        }}
    />
);
