import React from "react";

import styles from "./styles.modules.scss";

type LoaderType = { light?: boolean };

export const LoaderView = ({ light }: LoaderType) => (
    <div
        className={`loader ${styles.loader} ${
            light ? `light ${styles.light}` : ""
        }`}
    >
        <span />

        <span />

        <span />
    </div>
);
