export const parseLinkToEntry = (
    entry: InboxEventEntry[]
): number | undefined => {
    if (!entry.length) return;

    const { section, id } = entry[0];

    if (section.toLowerCase() !== "newsentries") return;

    return id;
};
