import React from "react";
import styles from "../../colors.modules.scss";

export const NaLatamN = () => {
    return (
        <svg
            id="na-latam-n"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1034.29 576.02"
        >
            <path
                d="m249.41,273.29l-120.61,84.52-4.81-4.55c-1.57-1.46-2.46-3.51-2.48-5.66v-35.03c.01-2.15.9-4.2,2.48-5.66l13.38-12.67v-9.38l-14.44-13.68c-1.57-1.46-2.46-3.51-2.48-5.66v-8.01h-25.37c-2.22,0-4.36-.83-5.98-2.34l-36.3-34.38v4.69c0,4.42-3.79,8.01-8.46,8.01s-8.45-3.59-8.45-8.01v-36.72l-14.44-13.67c-1.57-1.46-2.47-3.51-2.48-5.66v-31.99c0-2.15.91-4.2,2.48-5.66l14.44-13.67v-20.74H8.45c-4.67,0-8.45-3.58-8.45-8v-16.04c.01-2.15.91-4.2,2.48-5.66l18.98-32.01c1.63-1.51,3.76-2.35,5.98-2.34h101.48c2.22,0,4.36.83,5.98,2.34l10.93,10.35,26.79-25.36c3.37-3.13,8.59-3.13,11.96,0l14.44,13.67h14.47c3.36-.04,6.41,1.89,7.81,4.94,1.31,3,.57,6.51-1.84,8.73l-30.82,29.19v20.2h24.85v-24.04c0-4.42,3.78-8,8.45-8h46.51c2.22,0,4.36.83,5.98,2.35,1.57,1.46,2.46,3.51,2.48,5.66v34.04c0,4.42-3.78,8-8.46,8h-17.64l-8.46,8.01,9.88,9.35c2.4,2.23,3.14,5.73,1.83,8.73-1.4,3.05-4.46,4.98-7.82,4.94h-27.15l-49.49,46.86v22.21c0,4.36-3.7,7.93-8.3,8.01-4.61.07-8.44-3.36-8.6-7.72l-.24-6.26-48.92.19v29.81c0,.67-.09,1.35-.27,2h25.64c4.67,0,8.46,3.59,8.46,8.01v12.71l8.56,8.11,10.94-10c1.61-1.46,3.7-2.25,5.87-2.25h67.08c2.22,0,4.36.83,5.98,2.34l13.61,12.89Z"
                style={{ fill: styles.naLatamNColorBg }}
            />

            <path
                d="m230.26,38.68c-2.19,0-4.27-.86-5.82-2.41l-16.46-16.45c-1.55-1.55-2.42-3.65-2.42-5.83,0-4.54,3.7-8.21,8.24-8.21h32.92c2.18,0,4.27.86,5.82,2.41l16.45,16.44c1.54,1.55,2.41,3.63,2.41,5.82,0,4.55-3.68,8.23-8.23,8.23h-32.91Z"
                style={{ fill: styles.naLatamNColorBg }}
            />
        </svg>
    );
};
