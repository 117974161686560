import React, { useState } from "react";

import { PLAY_SOUND } from "../../constants/types";
import { useSendCommand } from "../../hooks/use-send-command";

import { PressableView } from "./view";

type PressableType = {
    children: JSX.Element;
    animationSize?: "small" | "large";
    sound?: string;
    disabled?: boolean;
};

export const PressableController = ({
    children,
    animationSize,
    sound = "OKButton",
    disabled,
}: PressableType) => {
    const [state, setState] = useState<"initial" | "released" | "pressed">(
        "initial"
    );

    const sendCommand = useSendCommand();

    const press = () => {
        if (disabled) return;

        setState("pressed");
    };

    const release = () => {
        if (disabled) return;

        setState("released");
    };

    const handlePointerDown = press;
    const handlePointerUp = release;

    const handlePointerLeave = () => {
        if (disabled) return;
        if (state !== "pressed") return;

        release();
    };

    const handleClick = () => {
        if (disabled) return;

        sendCommand(PLAY_SOUND, sound);
    };

    return (
        <PressableView
            state={state}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerLeave}
            animationSize={animationSize}
            onClick={handleClick}
        >
            {children}
        </PressableView>
    );
};
