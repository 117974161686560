import { withStyle } from "../../hocs/with-style";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { ArticleView } from "./view";

type ArticleType = {
    article: InboxArticle;
    Link: (props: unknown) => JSX.Element;
    prevLinkProps?: Record<string, unknown>;
    nextLinkProps?: Record<string, unknown>;
    showHomeCta?: boolean;
    style: { direction: string };
};

export const ArticleContainer = (props: ArticleType) => {
    const loc = useLocation();
    const { pathname } = loc;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return withStyle(ArticleView)(props);
};
