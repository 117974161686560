import { parsePadding } from "../padding";
import { parseStatsTable } from "./stats-table";
import { parseArticleBlockType } from "../type";

import { parseImageSrc } from "./image-src";

export const parseBlock = ({
    author,
    authorThumbnail,
    body,
    color,
    cta,
    embed,
    embedVideo,
    id,
    image,
    imageUrl,
    label,
    order,
    padding,
    position,
    quote,
    statsTable,
    textBlock,
    thumbnail,
    title,
    type,
    url,
    urlType,
    variant,
}: any): InboxArticleBlock => ({
    author,
    authorThumbnail: authorThumbnail && parseImageSrc(authorThumbnail),
    body,
    color,
    cta,
    embed,
    embedVideo,
    id,
    image,
    imageUrl,
    label,
    padding: padding && parsePadding(padding),
    position,
    order,
    quote,
    stats: statsTable && parseStatsTable(statsTable),
    textBlock,
    thumbnail,
    title,
    type: parseArticleBlockType(type),
    url,
    urlType,
    variant,
});
