import React, { useContext } from "react";

import { EsportContext } from "../store";
import RegionModalView from "./region-modal-view";
import { RegionModalProps } from "./region-modal-props";
import { EsportRegion } from "../types";

export const RegionModalController = (incomingProps: {
    globals: Record<string, string>;
}) => {
    const { globals } = incomingProps;
    const { state, dispatch } = useContext(EsportContext);

    const {
        selectedRegions,
        regionsAvailable,
        isRegionSelectorModalOpen,
        canChangeRegion,
    } = state;

    if (!isRegionSelectorModalOpen || !canChangeRegion) {
        return null;
    }

    const props: RegionModalProps = {
        globals,

        selectedRegions,

        regionsAvailable,

        selectRegion: (region: EsportRegion) => {
            if (selectedRegions.length === 3) {
                return;
            }

            dispatch({ type: "SELECT_REGION", region });
        },

        unselectRegion: (region: EsportRegion) => {
            dispatch({ type: "UNSELECT_REGION", region });
        },

        closeModal: () => {
            dispatch({
                type: "SET_REGION_MODAL_OPEN",
                isRegionSelectorModalOpen: false,
            });
        },
    };

    return <RegionModalView {...props} />;
};
