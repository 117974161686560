import React, { useEffect } from "react";

export const useTime = (): Date => {
    const [currentDate, setCurrentDate] = React.useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return currentDate;
};
