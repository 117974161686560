type ColorHexCode = `#${string}`;
import { EsportRegion } from "supercell-frontend-base/src/components/esport/types";
import styles from "./colors.modules.scss";

const defaultRegionColor = "#000000";

const regionColors: Record<EsportRegion, ColorHexCode> = {
    EMEA: styles.emeaColor,
    APAC: styles.apacColor,
    "LATAM S": styles.latamSColor,
    "NA & LATAM N": styles.naLatamNColor,
};

export const regionLabels: Record<EsportRegion, string> = {
    EMEA: "EMEA",
    APAC: "APAC",
    "LATAM S": "SOUTH AMERICA",
    "NA & LATAM N": "NORTH AMERICA",
};

export const getRegionColor = (region?: string) => {
    if (!region) {
        return defaultRegionColor;
    }

    const reg =
        region in regionColors ? regionColors[region] : defaultRegionColor;

    return reg;
};

export const addRegionToArticleCategories = (
    article: InboxEsportArticle,
    regionsSelected: EsportRegion[]
) => {
    const { esportRegions } = article;

    const selectedRegion = esportRegions.find((r) =>
        regionsSelected.includes(r)
    );

    const category = selectedRegion
        ? {
              title: regionLabels[selectedRegion],
              color: getRegionColor(selectedRegion),
          }
        : {
              title: regionLabels[esportRegions[0] ?? ""],
              color: getRegionColor(esportRegions[0]),
          };

    return {
        ...article,
        categories: [category],
    };
};
