import React from "react";

import { VideoProvider } from "../../constants/types";

import { VideoView } from "./view";

type VideoBlockType = {
    caption?: {
        author: string;
        title: string;
    };
    embed: {
        id: string;
        livepid?: string;
        provider: VideoProvider;
        url: string;
    };
    hideFilter?: boolean;
    hideProvider?: boolean;
    live?: boolean;
    thumbnail: ImageSourceSets;
    style: { [key in string]: string };
};

export const VideoController = (props: VideoBlockType) => {
    const { embed } = props;
    const { provider } = embed;

    return <VideoView {...props} provider={provider} />;
};
