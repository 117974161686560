import React from "react";
import { FormattedMessage, FormattedDateParts } from "react-intl";

import styles from "./styles.modules.scss";

export const DateOrTimeView = ({
    value,
    formattedMessageProps,
}: {
    value: number;
    formattedMessageProps?: { id: string; values: Record<string, number> };
}) => {
    return (
        <div className={`date-or-time ${styles.dateOrTime}`}>
            {formattedMessageProps ? (
                <FormattedMessage {...formattedMessageProps} />
            ) : (
                <FormattedDateParts day="2-digit" month="long" value={value}>
                    {([month, monthLiteral, day, dayLiteral]) => {
                        return (
                            <>
                                <div className={`month ${styles.month}`}>
                                    {month.value}
                                </div>

                                <div className={`literal ${styles.literal}`}>
                                    {monthLiteral.value}
                                </div>

                                {day.value && (
                                    <div className={`day ${styles.day}`}>
                                        {day.value}

                                        {dayLiteral?.value ?? ""}
                                    </div>
                                )}
                            </>
                        );
                    }}
                </FormattedDateParts>
            )}
        </div>
    );
};
