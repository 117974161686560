import React from "react";

import { useStateCountdown } from "./state-countdown";
import { useEffectStateUpdater } from "./effect-state-updater";

export const withCountdown =
    (getTarget: (props: { timerTarget: number }) => number, frequency = 1000) =>
    (Component: typeof React.Component) =>
    (props: Record<string, unknown>) => {
        const target = getTarget(props);

        const [countdown, setCountdown] = useStateCountdown(
            target - Date.now()
        );

        useEffectStateUpdater(frequency, target, setCountdown);

        const injectedProps = {
            ...props,
            countdown: target && countdown,
        };

        return <Component {...injectedProps} />;
    };
