import { z, ZodError } from "zod";

export interface Parseable<T> {
    parse: (input: unknown) => T;
}

const ApplePlatform = z.enum(["ios", "osx"]);
const AndroidPlatform = z.enum(["android", "amazon"]);
const DevelopmentPlatform = z.enum(["windows"]);

const stringToLowercase = (val: unknown) => {
    if (typeof val !== "string") {
        return val;
    }

    return val.toLowerCase();
};

const Platform = z.preprocess(
    stringToLowercase,
    ApplePlatform.or(AndroidPlatform).or(DevelopmentPlatform)
);

export const GameParams = z.object({
    channel: z.string().or(z.number()).nullish(),
    country: z.string().optional(),
    platform: Platform.optional(),
    playerTag: z.string().nullish(),
    xp: z.number().nullish(),
    jwt: z.string().nullish(),
});

export type GameParams = z.infer<typeof GameParams>;

export function parseGameParams<T>(
    input: unknown,
    parser: Parseable<T>
): { output: T; validationError?: ZodError } {
    try {
        return { output: parser.parse(input) };
    } catch (ex) {
        if (ex instanceof ZodError) {
            return { output: input as T, validationError: ex };
        }

        throw ex;
    }
}
