import React from "react";

import styles from "./styles.modules.scss";

export const PressableView = ({
    state,
    children,
    animationSize = "large",
    onPointerDown,
    onPointerUp,
    onPointerLeave,
    onClick,
}: {
    state: "initial" | "released" | "pressed";
    children: JSX.Element;
    animationSize?: "small" | "large";
    onPointerDown: () => void;
    onPointerUp: () => void;
    onPointerLeave: () => void;
    onClick: () => void;
}) => (
    <div
        className={`
            pressable ${styles.pressable}
            is-${state} ${styles[state] || ""}
            animation-${animationSize} ${styles[`${animationSize}Animation`]}
        `}
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
        onPointerLeave={onPointerLeave}
        onClick={onClick}
    >
        {children}
    </div>
);
