import React from "react";
import { IntlProvider, IntlShape } from "react-intl";

import { NewLabel } from "./new-label";

import styles from "./styles.modules.scss";

type ContentCardViewProps = {
    id: number | string;
    children: JSX.Element | JSX.Element[];
    direction: string;
    lang: string;
    pinned?: boolean;
    isNew: boolean;
    style: { [key in string]: string | number };
    intl: IntlShape;
};

export const ContentCardView = ({
    children,
    direction,
    intl,
    isNew,
    pinned,
    lang,
    style,
}: ContentCardViewProps) => {
    const getIcon = () => {
        if (isNew) {
            return (
                <div className={`new ${styles.new}`}>
                    <NewLabel />
                </div>
            );
        }

        if (pinned) {
            return <div className={`pinned-icon ${styles.pinnedIcon}`}></div>;
        }

        return null;
    };

    return (
        <IntlProvider {...intl} locale={lang}>
            <div
                className={`content-card ${styles.contentCard} ${direction} ${styles[direction]}`}
                style={style}
            >
                <div className={`card-box ${styles.cardBox}`}>{children}</div>

                <div className={`overlay ${styles.overlay}`}>
                    <div className={`state-icons ${styles.stateIcons}`}>
                        {getIcon()}
                    </div>
                </div>
            </div>
        </IntlProvider>
    );
};
