import { useEffect } from "react";
import { handleFontSizeChange } from "./handle-set-font-size-change";
import { useReferenceWidth } from "../../hocs/with-reference-width";

export const useSetBaseFontSizeEffect = () => {
    const referenceWidth = useReferenceWidth();

    useEffect(() => {
        const handler = handleFontSizeChange(referenceWidth);

        setTimeout(handler, 0);

        window.addEventListener("resize", handler);

        const timeout = setInterval(() => {
            handler();
        }, 250);

        return () => {
            clearTimeout(timeout);
            window.removeEventListener("resize", handler);
        };
    }, []);
};
