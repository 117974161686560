import { normalizeLang } from "./normalize-lang";

export const getLocale = (
    localization: LocalizationConfig,
    lang: string
): Locale => {
    const { default: defaultLang, available, locales } = localization;

    const normalizedLang = normalizeLang(lang);
    const validLang = available.includes(normalizedLang)
        ? normalizedLang
        : defaultLang;
    const locale = {
        ...locales[defaultLang],
        ...locales[validLang],
        lang: validLang,
    };

    return locale;
};
