import React from "react";
import { VoteModalProps } from ".";
import { VoteModalView } from "./vote-modal-view";
import { usePollContext } from "../../stores/poll";

type WithStyleProps = {
    style: { [key in string]: string };
};

type WithStyle<P> = (props: P & WithStyleProps) => JSX.Element | null;

export const VoteModalController: WithStyle<VoteModalProps> = ({
    isOpen,
    pollId,
    options,
    ...viewProps
}) => {
    const {
        state: { votes, votingState, polls },
        canVote,
        actions: { refreshPollInfo, vote },
    } = usePollContext();

    const [order, setOrder] = React.useState<number[]>([]);

    React.useEffect(() => {
        const order = ["1", "2", "3", "4", "5"].map(() => Math.random());
        setOrder(order);
    }, [pollId]);

    React.useEffect(() => {
        if (isOpen) {
            refreshPollInfo(pollId);
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "initial";
        }
    }, [isOpen]);

    if (!isOpen) {
        return null;
    }

    const pollResults = polls[pollId]?.results;

    const optionsWithResults = options
        .map((option) => {
            const optionResult = pollResults?.find(
                (result, index) => option.id === String(index + 1)
            );

            return {
                ...option,
                result: optionResult,
                isLeading:
                    optionResult && pollResults
                        ? pollResults.every((x) => x <= optionResult)
                        : false,
            };
        })
        .sort((a, b) =>
            (order[Number(a.id) - 1] ?? 1) < (order[Number(b.id) - 1] ?? 1)
                ? -1
                : 1
        );

    const optionsTotalPercentage =
        pollResults?.reduce((v, acc) => acc + v, 0) ?? 0;
    const showResults =
        (polls[pollId]?.status === "closed" && optionsTotalPercentage > 0) ||
        Boolean(
            votes[pollId] &&
                pollResults &&
                pollResults.length > 0 &&
                optionsTotalPercentage > 0
        );

    return (
        <VoteModalView
            userVote={votes[pollId] ?? null}
            canVote={canVote(pollId) && polls[pollId]?.status !== "closed"}
            vote={vote}
            options={optionsWithResults}
            showResults={showResults}
            isError={votingState === "fail"}
            isVoting={votingState === "voting"}
            {...viewProps}
        />
    );
};
