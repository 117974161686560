import React from "react";

import { FormattedDate } from "react-intl";

import { Image } from "../../image";

import { HomeCta } from "./home-cta";

import styles from "./styles.modules.scss";

type HeroViewType = {
    title: string;
    categories: { title: string; color: string }[];
    date: number;
    image?: ImageSourceSets;
    hideTitle?: boolean;
    showHomeCta?: boolean;
};
export const HeroView = ({
    image,
    categories,
    date,
    title,
    hideTitle,
    showHomeCta,
}: HeroViewType) => {
    const style = {
        "--heroBackground": categories[0]?.color,
    } as React.CSSProperties;

    return (
        <div
            className={`
            hero ${styles.hero}
            ${showHomeCta ? `show-home-cta ${styles.showHomeCta}` : ""}
        `}
            style={style}
        >
            {image && (
                <div className={`image ${styles.image}`}>
                    <Image sources={image} />
                </div>
            )}

            {showHomeCta && (
                <div className={`home-cta ${styles.homeCta}`}>
                    <HomeCta />
                </div>
            )}

            <div className={`container ${styles.container}`}>
                <div className={`meta ${styles.meta}`}>
                    <div className={`categories ${styles.categories}`}>
                        {categories.map(({ title, color }) => {
                            const style = {
                                "--categoryColor": color,
                            } as React.CSSProperties;

                            return (
                                <div
                                    key={title}
                                    className={`category ${styles.category}`}
                                    style={style}
                                >
                                    {title}
                                </div>
                            );
                        })}
                    </div>

                    <div className={`date ${styles.date}`}>
                        <FormattedDate
                            value={date}
                            month="long"
                            day="numeric"
                        />
                    </div>
                </div>

                {!hideTitle && (
                    <div className={`title ${styles.title}`}>{title}</div>
                )}
            </div>
        </div>
    );
};
