import React from "react";
import styles from "../../colors.modules.scss";

export const Apac = () => {
    return (
        <svg
            id="apac"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1034 576.02"
        >
            <g id="APAC">
                <path
                    d="m928.2,118.63l6.71,6.4c1.74,1.64,2.72,3.92,2.72,6.3v55.71c-.06,3.67-2.35,6.94-5.79,8.24-3.49,1.38-7.46.63-10.21-1.93l-21.49-20.42.6-2.71,27.45-51.59Z"
                    style={{ fill: styles.apacColorBg }}
                />

                <path
                    d="m712.69,224.36v49.64c0,2.38.99,4.66,2.72,6.3l37.49,35.67c1.79,1.69,4.17,2.63,6.63,2.61,1.23,0,2.44-.23,3.58-.68,3.44-1.29,5.74-4.56,5.79-8.24v-31.99l28.14-26.71,15.79,14.99h0l.07,46.48c0,2.39.99,4.66,2.72,6.3l19.92,18.95c3.73,3.49,9.53,3.49,13.26,0,.15-.14.29-.28.43-.43,3.24-3.48,3.05-8.93-.43-12.18l-17.17-16.34v-24.93l13.87,13.2c3.73,3.48,9.53,3.48,13.26,0l19.92-18.93c1.74-1.64,2.72-3.92,2.72-6.31l-25.77-24.51-142.96-22.9Z"
                    style={{ fill: styles.apacColorBg }}
                />

                <path
                    d="m851.4,413.28c-1.59,1.5-3.7,2.33-5.89,2.32-2.19,0-4.3-.82-5.9-2.32l-35.4-33.71s-.09-.08-.13-.12c-.09-.09-.17-.17-.26-.26-2.88-3.1-2.71-7.94.39-10.82,3.32-3.1,8.47-3.1,11.79,0l35.48,33.78c.06.06.11.11.17.17.05.05.09.09.13.14,2.88,3.1,2.71,7.94-.39,10.83Z"
                    style={{ fill: styles.apacColorBg }}
                />

                <path
                    d="m864.89,379.27c-1.29-2.97-.57-6.43,1.8-8.64l16.68-15.87,16.62-16.1c2.43-2.31,5.98-3.01,9.1-1.78,3.08,1.14,5.15,4.06,5.19,7.35v31.99c0,4.38-3.74,7.93-8.34,7.93h-33.35c-3.3.04-6.32-1.87-7.69-4.88Z"
                    style={{ fill: styles.apacColorBg }}
                />

                <path
                    d="m914.28,416.1h-41.68c-4.6,0-8.34-3.55-8.34-7.93s3.73-7.93,8.33-7.93h41.69c4.6,0,8.33,3.55,8.33,7.93s-3.73,7.93-8.33,7.93Z"
                    style={{ fill: styles.apacColorBg }}
                />

                <path
                    d="m1006.01,510.76c-.01,2.12-.9,4.15-2.45,5.61l-33.35,31.72c-1.6,1.5-3.7,2.33-5.89,2.32h-33.36c-4.6,0-8.34-3.55-8.34-7.93v-12.08l-11.8-11.24h-7.68l-14.23,13.54c-1.6,1.5-3.7,2.33-5.9,2.32h-35.43c-4.6,0-8.34-3.55-8.34-7.93v-40.16c0-2.12.89-4.15,2.44-5.61l33.35-31.72c1.59-1.5,3.7-2.34,5.9-2.32h13.22l15.28-14.53c1.59-1.5,3.7-2.33,5.9-2.33h17.7c4.61,0,8.34,3.55,8.34,7.93v14.91h14.59v-27.76c.04-3.27,2.08-6.17,5.15-7.32,3.1-1.23,6.64-.57,9.09,1.71l14.59,13.88c1.55,1.46,2.44,3.48,2.44,5.61v12.57l16.34,15.53c1.55,1.45,2.43,3.48,2.44,5.6v37.67h.01Z"
                    style={{ fill: styles.apacColorBg }}
                />

                <path
                    d="m959.51,378.27c3.32-3.1,8.47-3.1,11.79,0l13.1,12.54h14.3c4.6,0,8.33,3.55,8.33,7.93v13.55l13.24,12.57h.01c.13.13.26.25.38.39,2.88,3.1,2.71,7.94-.39,10.83-3.32,3.1-8.46,3.1-11.79,0l-48.97-46.6c-.14-.12-.27-.25-.39-.39-2.88-3.1-2.71-7.94.39-10.82Z"
                    style={{ fill: styles.apacColorBg }}
                />

                <path
                    d="m945.22,306.9l-16.68-15.86c-3.32-3.1-8.47-3.1-11.79,0-3.1,2.88-3.27,7.73-.39,10.82.07.08.14.15.22.23.05.05.11.11.17.16l16.68,15.86c3.32,3.1,8.47,3.1,11.79,0,3.1-2.88,3.27-7.73.39-10.82-.12-.14-.25-.26-.39-.39Z"
                    style={{ fill: styles.apacColorBg }}
                />

                <path
                    d="m930.99,346.55v-10.25h-.03c0-4.38,3.76-7.93,8.37-7.93s8.33,3.55,8.33,7.93v10.25c0,4.38-3.72,7.93-8.33,7.93s-8.34-3.55-8.34-7.93Z"
                    style={{ fill: styles.apacColorBg }}
                />

                <path
                    d="m975.17,202.63v-53.5c0-4.92-4.19-8.92-9.37-8.92s-9.37,3.99-9.37,8.92v53.5c0,4.92,4.19,8.92,9.37,8.92s9.37-3.98,9.37-8.92Z"
                    style={{ fill: styles.apacColorBg }}
                />

                <path
                    d="m1022.93,535.5c2.51-2.35,6.42-2.35,8.93,0,.1.1.2.2.31.31,2.17,2.35,2.04,6.01-.31,8.19l-31.59,30.05c-2.51,2.34-6.41,2.34-8.93,0l-.29-.28c-2.19-2.35-2.06-6.03.29-8.22l31.59-30.04Z"
                    style={{ fill: styles.apacColorBg }}
                />

                <path
                    d="m936.52,242.6l10.74-11.33c2.1-2.25,2.1-5.75,0-8.01-1.95-2.1-5.24-2.22-7.34-.27-.1.09-.18.18-.27.27l-10.77,11.35c-2.1,2.25-2.1,5.75,0,8.01,1.97,2.1,5.27,2.21,7.37.24l.03-.03s.03-.03.04-.04c.07-.07.13-.13.19-.2Z"
                    style={{ fill: styles.apacColorBg }}
                />
            </g>
        </svg>
    );
};
