import React from "react";

import { Regular } from "../regular";
import { ImageArticleType } from "./type";

export const ImageArticleView = (props: ImageArticleType) => (
    <div className="article-image">
        <Regular {...props} external={true} ctaHidden={!props.url} />
    </div>
);
