import React from "react";

import {
    TYPE_ARTICLE_COMMUNITY_LIVE_STREAM,
    TYPE_ARTICLE_COMMUNITY_VIDEO,
    TYPE_ARTICLE_NEWS_IMAGE,
    TYPE_ARTICLE_NEWS_EXTERNAL,
    TYPE_ARTICLE_NEWS_IMAGE_LANDSCAPE,
    TYPE_ARTICLE_NEWS_IMAGE_SQUARE,
    TYPE_ARTICLE_NEWS_REGULAR,
    TYPE_ARTICLE_NEWS_VIDEO,
    TYPE_ARTICLE_ESPORT_NEWS_REGULAR,
    TYPE_ESPORT_REWATCH_MATCH_ENTRY,
} from "../../../constants/types";

import { ImageArticle } from "./image";
import { ImageArticleType } from "./image/type";
import { Regular } from "./regular";
import { RegularArticleType } from "./regular/type";
import { VideoArticle } from "./video";
import { VideoArticleType } from "./video/type";

import { RewatchMatchEntry } from "./rewatch-match";

type ListArticleType = {
    data: ImageArticleType | RegularArticleType | VideoArticleType;
};

export const ListArticleView = ({ data }: ListArticleType) => {
    const { type } = data;

    switch (type) {
        case TYPE_ARTICLE_COMMUNITY_LIVE_STREAM:
            return (
                <VideoArticle
                    {...data}
                    categories={[]}
                    live={true}
                    postDate={undefined}
                />
            );
        case TYPE_ARTICLE_COMMUNITY_VIDEO:
            return (
                <VideoArticle {...data} categories={[]} postDate={undefined} />
            );

        case TYPE_ARTICLE_NEWS_EXTERNAL:
            return <Regular {...data} external={true} />;

        case TYPE_ESPORT_REWATCH_MATCH_ENTRY:
            return <RewatchMatchEntry {...data} />;
        case TYPE_ARTICLE_NEWS_IMAGE_LANDSCAPE:
            return <ImageArticle {...data} />;
        case TYPE_ARTICLE_NEWS_IMAGE_SQUARE:
            return <ImageArticle {...data} />;
        case TYPE_ARTICLE_NEWS_IMAGE:
            return <ImageArticle {...data} />;
        case TYPE_ARTICLE_NEWS_VIDEO:
            return <VideoArticle {...data} />;
        case TYPE_ARTICLE_ESPORT_NEWS_REGULAR:
            return <Regular {...data} />;
        case TYPE_ARTICLE_NEWS_REGULAR:
            return <Regular {...data} />;

        default:
            return <div>[MISSING COMPONENT]</div>;
    }
};
