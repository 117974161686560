import React, { Suspense } from "react";
import { withSentryRoutes } from "../../tracing/sentry";
import { HashRouter, Route } from "react-router-dom";

export const withRoutes = (Component: typeof React.Component) => (props) => {
    const Routes = withSentryRoutes();

    const routes = props.routes.reduce(
        (injectedRoutes, route) => {
            return [
                ...injectedRoutes,
                { ...route, path: `/:lang${route.path}` },
            ];
        },
        [...props.routes]
    );

    return (
        <Suspense>
            <HashRouter>
                <Component {...props}>
                    <Routes>
                        {routes.map((route) => (
                            <Route
                                key={route.path}
                                path={route.path}
                                element={<route.element />}
                            />
                        ))}
                    </Routes>
                </Component>
            </HashRouter>
        </Suspense>
    );
};
