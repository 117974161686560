import {
    TYPE_EVENT_CUSTOM,
    TYPE_EVENT_POLL,
} from "../../constants/types/events";
import { parseCtas } from "../ctas";
import { parseLang } from "../lang";
import { parseTimestamp } from "../timestamp";

import { parseChannelUrlTargeting } from "./channel-url-targeting";
import { parseLinkToEntry } from "./link-to-entry";

export const parseEvent = ({
    background,
    ctas,
    displayTimer,
    entry,
    eventChannelUrl,
    gameEventID,
    hideTitle,
    id,
    lang,
    newUntil,
    pollTitle,
    postDate,
    sortDate,
    targeting,
    timerPrimaryColor,
    timerSecondaryColor,
    timerField,
    timerLabel,
    timerKind,
    timerTarget,
    title,
    type,
    options,
}: any): InboxEvent => {
    return {
        background: background,
        channelUrlTargeting:
            eventChannelUrl && parseChannelUrlTargeting(eventChannelUrl),
        ctas: parseCtas(ctas || []),
        displayTimer,
        entry: entry && parseLinkToEntry(entry),
        gameEventID: gameEventID && parseInt(gameEventID, 10),
        hideTitle,
        id,
        lang: parseLang(lang),
        newUntil: parseTimestamp(newUntil),
        pollTitle,
        postDate: parseTimestamp(postDate),
        sortDate: parseTimestamp(sortDate),
        targeting,
        timerColors: {
            primary: timerPrimaryColor,
            secondary: timerSecondaryColor,
        },
        timerField,
        timerLabel,
        timerTarget: parseTimestamp(timerTarget),
        timerKind,
        title,
        type: type === "pollEntry" ? TYPE_EVENT_POLL : TYPE_EVENT_CUSTOM,
        options,
    };
};
