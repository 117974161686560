export const localization: LocalizationConfig = {
    default: "en",
    available: [
        "en",
        "fr",
        "de",
        "it",
        "ja",
        "ko",
        "pt",
        "pl",
        "ru",
        "es",
        "tr",
        "zh",
    ],

    locales: {
        zh: {
            fonts: {
                primaryFont:
                    "Lantinghei SC, Hiragino Sans GB, SimHei, Arial, sans-serif",
                secondaryFont:
                    "Lantinghei SC, Hiragino Sans GB, SimHei, Arial, sans-serif",
                titleBoldValue: "bold",
            },
        },
        en: {
            fonts: {
                primaryFont: "lilita",
                secondaryFont: "nunito_semibold",
            },
        },
        fr: {
            fonts: {
                primaryFont: "lilita",
                secondaryFont: "nunito_semibold",
            },
        },
        de: {
            fonts: {
                primaryFont: "lilita",
                secondaryFont: "nunito_semibold",
            },
        },

        it: {
            fonts: {
                primaryFont: "lilita",
                secondaryFont: "nunito_semibold",
            },
        },

        ko: {
            fonts: {
                titleBoldValue: "bold",
            },
        },
        ja: {
            fonts: {
                secondaryFont: "Arial, sans-serif",
                primaryFont: `"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ ゴシック", "MS Gothic", Arial, sans-serif`,
                titleBoldValue: "bold",
            },
        },

        pl: {
            fonts: {
                primaryFont: `"Pusia Bold", Arial, sans-serif`,
            },
        },
        ru: {
            fonts: {
                primaryFont: `"Pusia Bold", Arial, sans-serif`,
            },
        },
        es: {
            fonts: {
                primaryFont: "lilita",
                secondaryFont: "nunito_semibold",
            },
        },
        tr: {
            fonts: {
                primaryFont: `"Pusia Bold", Arial, sans-serif`,
                secondaryFont: "nunito_semibold",
            },
        },
    },
};
