import { copyObject } from "../utils/copy-object";

import { parseArticle } from "./articles";
import { parseEvent } from "./events";
import { parseSocialLinks } from "./social-links";

// TODO: More robust runtime type checks here
export const inboxParser = (data: any) => {
    data = copyObject(data);

    const { entries, globals } = data as { entries: any; globals: Globals };
    const articles: InboxArticle[] = (entries.newsEntries || []).map(
        (article: any) => parseArticle(article)
    );

    const events: InboxEvent[] = (entries.eventEntries || []).map(
        (event: any) => parseEvent(event)
    );

    const socialLinks = parseSocialLinks(data.socialLinks);

    return { globals, articles, events, socialLinks };
};
