import React from "react";
import { HeroView } from "./view";

type HeroType = {
    title: string;
    categories: { title: string; color: string }[];
    date: number;
    image?: ImageSourceSets;
    surTitle?: string;
    hideTitle?: boolean;
    showHomeCta?: boolean;
};

export const HeroController = ({
    categories,
    date,
    image,
    hideTitle,
    showHomeCta,
    surTitle,
    title,
}: HeroType) => {
    const actualCategories = surTitle
        ? [
              {
                  title: surTitle,
                  color: "initial",
              },
          ]
        : categories;

    const props = {
        categories: actualCategories,
        date,
        image,
        hideTitle,
        showHomeCta,
        surTitle,
        title,
    };

    return <HeroView {...props} />;
};
