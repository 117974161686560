import React from "react";

import { FormattedMessage } from "react-intl";

import { Button } from "../../../button";
import { Image } from "../../../image";

import { Timer } from "./timer";

import styles from "../styles.modules.scss";

export const DefaultSlideView = ({
    background,
    countdown,
    ctas,
    displayTimer,
    hideTitle,
    timerColors,
    timerLabel,
    title,
}) => {
    const { primary, secondary } = timerColors;
    const style = displayTimer
        ? ({
              ...(primary && {
                  "--timer-color-primary": primary,
              }),
              ...(secondary && {
                  "--timer-color-secondary": secondary,
              }),
          } as React.CSSProperties)
        : undefined;

    return (
        <div className={`slide default ${styles.slide}`} style={style}>
            <div
                className={`content ${styles.content} ${
                    displayTimer ? "with-timer" : ""
                }`}
            >
                {background && (
                    <div className={`image ${styles.image}`}>
                        <Image sources={background} cover />
                    </div>
                )}

                {title && !hideTitle && (
                    <>
                        <div className={`filter ${styles.filter}`} />

                        <div className={`title ${styles.title}`}>{title}</div>
                    </>
                )}

                <div className={`wrapper ${styles.wrapper}`}>
                    {displayTimer && (
                        <div className={`timer ${styles.timer}`}>
                            <Timer
                                countdown={countdown}
                                timerLabel={timerLabel}
                            />
                        </div>
                    )}

                    {ctas.length ? (
                        <div
                            className={`ctas ${
                                ctas.length === 1 ? "single" : "multi"
                            } ${styles.ctas}`}
                        >
                            {ctas.map(({ label, onClick, variant }, index) => (
                                <Button
                                    key={index}
                                    onClick={onClick}
                                    variant={variant}
                                >
                                    <span className="buttonTextWrapper">
                                        {label || (
                                            <FormattedMessage id="globalText.langGo" />
                                        )}
                                    </span>
                                </Button>
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
