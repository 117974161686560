import React from "react";
import { createRoot } from "react-dom/client";

import { App } from "./components/app";

import {
    initialiseSentry,
    captureManualException,
} from "supercell-frontend-base/src/tracing/sentry";

const sendCommand =
    window["sendCommand"] ??
    ((command: string) => {
        console.log("sendCommand", command);
        window.location.href = command;
    });

window.setParams = () => {
    // Empty function here since sometimes the game calls setParams too early
};

window.sendCommand =
    window.sendCommand ||
    ((command: string) => {
        sendCommand(`brawlstars-inbox://${command}`);
    });

const trackEvent =
    window["trackEvent"] ??
    ((event) => {
        console.log("trackEvent", event);
        window["dataLayer"].push(event);
    });

window.trackEvent = window.trackEvent || trackEvent;

if (window.SENTRY_DSN) {
    initialiseSentry(
        {
            dsn: window.SENTRY_DSN,
            tracesSampleRate: 0.01,
        },
        window.BUILD_INFO
    );
}

if (window.BUILD_INFO) {
    console.log("Build", window.BUILD_INFO);
    console.log("Viewport size", {
        width: window.innerWidth,
        height: window.innerHeight,
    });
}

let root: ReturnType<typeof createRoot> | null = null;

const render = () => {
    const rootElement = document.getElementById("inbox");

    if (rootElement === null) {
        const msg = "Failed to render Inbox, could not find root element";
        console.error(msg);
        captureManualException(new Error(msg));
        return;
    }

    if (!root) {
        root = createRoot(rootElement);
    }

    root.render(<App sendCommand={sendCommand} trackEvent={trackEvent} />);
};

window.history.replaceState({ root: true }, "");
window.history.pushState({ home: true }, "");
window.onpopstate = ({ state }) => {
    sendCommand("brawlstars-inbox://hideLoader");

    if (state && state.root) window.history.forward();
};

window.addEventListener("load", () => {
    render();
});
