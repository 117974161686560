import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { TRACK_EVENT } from "supercell-frontend-base/src/constants/types";
import { useSendCommand } from "supercell-frontend-base/src/hooks/use-send-command";

import { filterLiveArticles } from "supercell-frontend-base/src/utils/targeting/filter-live-articles";

import { CommunityView } from "./view";

export const CommunityController = () => {
    const loc = useLocation();
    const { pathname: pagePath } = loc;

    const sendCommand = useSendCommand();

    const filterArticles = (articles) => filterLiveArticles(articles);

    useEffect(() => {
        sendCommand(TRACK_EVENT, {
            event: "Pageview",
            pagePath,
            pageTitle: "community",
        });

        window.history.pushState({ home: true }, "");
    }, []);

    return <CommunityView filterArticles={filterArticles} />;
};
