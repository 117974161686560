import React from "react";
import { Button } from "../../../button";
import { Image } from "../../../image";
import styles from "../styles.modules.scss";

type VoteSlideViewType = (props: {
    background: ImageSourceSets;
    cta: { className: string; label: JSX.Element; onClick: () => void };
    title: string;
    hideTitle: boolean;
}) => JSX.Element | null;

export const VoteSlideView: VoteSlideViewType = ({
    background,
    cta,
    title,
    hideTitle,
}) => (
    <div className={`slide vote ${styles.slide}`}>
        <div className={`content ${styles.content}`}>
            {background && (
                <div className={`image ${styles.image}`}>
                    <Image sources={background} cover />
                </div>
            )}

            {title && !hideTitle && (
                <>
                    <div className={`filter ${styles.filter}`} />

                    <div className={`title ${styles.title}`}>{title}</div>
                </>
            )}

            <div className={`wrapper ${styles.wrapper}`}>
                <div className={`ctas single ${styles.ctas} ${cta.className}`}>
                    <Button onClick={cta.onClick}>
                        <span className="buttonTextWrapper">{cta.label}</span>
                    </Button>
                </div>
            </div>
        </div>
    </div>
);
