import React from "react";

import { useOpenNewsArticle } from "../../../../hooks/use-open-news-article";

import { LinkView } from "./view";
import { LinkType } from "./type";
import { TYPE_ARTICLE_ESPORT_NEWS_REGULAR } from "../../../../constants/types";

export const LinkController = (props: LinkType) => {
    const openNewsArticle = useOpenNewsArticle();

    const handleClick = () => {
        if (!props.labelComponentProps?.data?.disableOnClick) {
            return openNewsArticle(
                props.labelComponentProps.data.id,
                props.label ?? props.labelComponentProps?.data?.title ?? "",
                props.labelComponentProps.data.type ===
                    TYPE_ARTICLE_ESPORT_NEWS_REGULAR
                    ? "esport"
                    : "news"
            );
        }
    };

    return <LinkView {...props} onClick={handleClick} />;
};
