import { useEffect } from "react";

export const useEffectStateUpdater = (frequency, target, updateCountdown) =>
    useEffect(() => {
        if (!target) return;

        const clear = () => {
            clearInterval(intervalId);
        };

        const intervalId = setInterval(() => {
            const millisecondsLeft = target - Date.now();

            if (millisecondsLeft < 1000) {
                updateCountdown(0);
                return clear();
            }

            updateCountdown(millisecondsLeft);
        }, frequency);

        return clear;
    }, []);
