import React from "react";

import { TYPE_EVENT_LIVE_OPS } from "../../../../constants/types";

import { useSendCommand } from "../../../../hooks/use-send-command";
import { useOpenNewsArticle } from "../../../../hooks/use-open-news-article";

import { parseCTAs } from "../parse-ctas";
import { handleCTAClick } from "../handle-cta-click";

import { DefaultSlideView } from "./view";

export const DefaultSlideController = ({
    timerKind,
    background,
    countdown,
    ctas,
    displayTimer,
    hideTitle,
    limit = 60,
    timerColors,
    timerLabel,
    title,
    type,
}) => {
    const sendCommand = useSendCommand();
    const openNewsArticle = useOpenNewsArticle();

    const handleClick = handleCTAClick(sendCommand, openNewsArticle, title);

    const limitedTitle = title.substring(0, limit);

    const getCtas = () => {
        if (!timerKind) {
            ctas =
                type === TYPE_EVENT_LIVE_OPS && countdown > 0
                    ? []
                    : parseCTAs(ctas, handleClick);

            return ctas;
        }

        if (timerKind === "SHOW_CTA") {
            return countdown > 0 ? [] : parseCTAs(ctas, handleClick);
        }

        if (timerKind === "HIDE_CTA") {
            return countdown > 0 ? parseCTAs(ctas, handleClick) : [];
        }

        return parseCTAs(ctas, handleClick);
    };

    const props = {
        background,
        countdown,
        ctas: getCtas(),
        displayTimer: displayTimer && countdown > 0,
        hideTitle,
        timerColors,
        timerLabel,
        title: limitedTitle,
    };

    return <DefaultSlideView {...props} />;
};
