import React, { useReducer } from "react";

import { gameContext } from "../contexts/game";
import { gameContextReducer } from "../contexts/game/reducers/game-context-reducer";

export const withGameContextProvider = (Component) => {
    const { Provider } = gameContext;

    return (props: unknown) => {
        const [state, dispatch] = useReducer(gameContextReducer, {
            settings: {
                game: {},
            },
            video: {},
        });

        return (
            <Provider value={{ state, dispatch }}>
                <Component {...props} />
            </Provider>
        );
    };
};
