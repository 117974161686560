import { withErrorBoundary } from "../../../hocs/with-error-boundary";
import { withGameContext } from "../../../hocs/with-game-context";
import { withLoadingView } from "../../../hocs/with-loading-view";
import { withLocalizationContext } from "../../../hocs/with-localization-context";
import { withSelectedLocale } from "../../../hocs/with-selected-locale";

import { withFilteredProps } from "./with-filtered-props";
import { withMemoization } from "./with-memoization";

import { TabController } from "./controller";
import { withData } from "./with-data";

export const TabContainer = withLocalizationContext(
    withGameContext(
        withSelectedLocale(
            withData(
                withFilteredProps(
                    withErrorBoundary(
                        withLoadingView(withMemoization(TabController))
                    )
                )
            )
        )
    )
);
