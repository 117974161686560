import React from "react";

import { ContentCard } from "../../../content-card";
import { ContentCardCaption } from "../../../content-card/caption";
import { Image } from "../../../image";
import { Pressable } from "../../../pressable";

import styles from "./styles.modules.scss";

export type RegularViewType = {
    ctaHidden?: boolean;
    author?: string;
    categories: { title: string; color: string }[];
    external?: boolean;
    id: string;
    type;
    lang: string;
    listingSize: string;
    newUntil: number;
    postDate: number;
    pinned?: boolean;
    thumbnail: ImageSourceSets;
    thumbnailAlt: ImageSourceSets;
    title: string;
    hasOnClick?: boolean;
    onClick: () => void;
};

export const RegularView = ({
    author,
    categories,
    external,
    id,
    lang,
    type,
    listingSize,
    newUntil,
    onClick,
    pinned,
    postDate,
    thumbnail,
    ctaHidden,
    thumbnailAlt,
    title,
    hasOnClick,
}: RegularViewType) => (
    <div
        data-testid={type}
        className={`
            article-regular ${styles.articleRegular}
            ${listingSize} ${styles[listingSize] || ""}
        `}
        onClick={onClick}
    >
        <Pressable
            disabled={!hasOnClick}
            animationSize={listingSize === "small" ? "small" : "large"}
        >
            <div className={`card ${styles.card}`}>
                <ContentCard
                    id={id}
                    lang={lang}
                    newUntil={newUntil}
                    pinned={pinned}
                >
                    <div className={`content ${styles.content}`}>
                        <div className={`thumbnail ${styles.thumbnail}`}>
                            <Image
                                sources={
                                    listingSize === "small"
                                        ? thumbnailAlt
                                        : thumbnail
                                }
                                cover={listingSize === "small"}
                            />
                        </div>

                        <div className={`caption ${styles.caption}`}>
                            <ContentCardCaption
                                ctaHidden={ctaHidden}
                                author={author}
                                cardId={id}
                                categories={categories}
                                external={external}
                                postDate={postDate}
                                title={title}
                            />
                        </div>
                    </div>
                </ContentCard>
            </div>
        </Pressable>
    </div>
);
