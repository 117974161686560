export const parseCreator = (data: any): InboxArticleCreator => {
    const [creator] = Object.values(data);
    const { name, thumbnail } = creator as {
        name: string;
        thumbnail?: { default: { path: string } };
    };
    const { default: image } = thumbnail || {};
    const { path } = image || {};

    return { name, icon: path } as InboxArticleCreator;
};
