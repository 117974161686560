import React from "react";

import { Article } from "../article";
import { Community } from "../community";
import { News } from "../news";
import { Esport } from "../esport";

import { apiConfig } from "./config/api-config";
import { localization } from "./config/localization";

import { commander } from "./commander";

import { generateRoute } from "./helper-generate-route";

import { AppView } from "./view";

export const AppController = ({ sendCommand, trackEvent }) => {
    trackEvent({
        event: "gtm.js",
        "gtm.start": Date.now(),
    });

    trackEvent({
        originalLocation: window.location.href,
    });

    const routes = [
        generateRoute("/news/:id/:slug/:variant", Article),
        generateRoute("/news/:id/:slug", Article),
        generateRoute("/news/:id", Article),
        generateRoute("/community", Community),
        generateRoute("/esport/*", () => <Esport />),
        generateRoute("/community/:type/*", Community),
        generateRoute("/news", () => <News />),
        generateRoute("/", () => <News />),
    ];

    const props = {
        apiConfig,
        commander: commander(sendCommand, trackEvent),
        localization,
        referenceWidth: 993,
        routes,
    };

    return <AppView {...props} />;
};
