import React from "react";

import { getFormattedMessageProps } from "./get-formatted-message-props";

import { DateOrTimeView } from "./view";

type DateOrTimeType = {
    timeStamp: number;
    limitInMilliseconds: number;
};

export const DateOrTimeController = ({
    timeStamp,
    limitInMilliseconds,
}: DateOrTimeType) => (
    <DateOrTimeView
        value={timeStamp}
        formattedMessageProps={getFormattedMessageProps(
            timeStamp,
            limitInMilliseconds
        )}
    />
);
