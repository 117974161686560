import React from "react";
import { OPEN_LINK } from "../../../../constants/types";
import { useSendCommand } from "../../../../hooks/use-send-command";

import { VideoArticleView } from "./view";
import { VideoArticleType } from "./type";

export const VideoArticleController = (props: VideoArticleType) => {
    const sendCommand = useSendCommand();

    const { creator, embed, embedVideo, listingSize } = props;
    const { name: creatorName, icon } = creator || {};
    const { url } = embed;

    const onClick = () => !embedVideo && sendCommand(OPEN_LINK, url);

    const small = !embedVideo && listingSize === "small";

    return (
        <VideoArticleView
            {...props}
            creatorName={creatorName}
            icon={icon}
            onClick={onClick}
            small={small}
        />
    );
};
