import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { VoteOption } from ".";
import { Button } from "../button";
import { Image } from "../image";
import { FormattedMessage } from "react-intl";
import styles from "./styles.modules.scss";
import { Pressable } from "../../components/pressable";

type OptionWithResult = VoteOption & {
    result: number | undefined;
    isLeading: boolean;
};

type VoteModalViewType = (props: {
    title: string;
    userVote: string | null;
    pollTitle: string;
    options: OptionWithResult[];
    showResults: boolean;
    canVote: boolean;
    isError: boolean;
    isVoting: boolean;
    vote: (optionId: string) => Promise<[void, void]>;
    onClose: () => void;
    style: { [key: string]: string | number };
}) => JSX.Element | null;

export const VoteModalView: VoteModalViewType = ({
    title,
    pollTitle,
    options,
    userVote,
    canVote,
    showResults,
    isError,
    isVoting,
    vote,
    onClose,
    style,
}) => {
    const [selected, setSelected] = React.useState<string | null>(null);
    const modalRoot = document.getElementById("modal-root");

    if (!modalRoot) {
        return null;
    }

    const hasVoted = userVote !== null;

    const onVote = () => {
        if (canVote && selected) {
            vote(selected).then(() => setSelected(null));
        }
    };

    const showVoteButton = canVote && selected;
    const showCloseButton = !showVoteButton;

    const aspectRatio = window.innerWidth / window.innerHeight;

    const validOptions = options.filter((x) => x.image);

    const contents = (
        <div className={classNames(["vote-modal", styles.modal])} style={style}>
            <div
                className={classNames([
                    "vote-modal-content",
                    styles.modalContent,
                    { tablet: aspectRatio > 0.666 },
                ])}
            >
                <h1
                    className={classNames([
                        "title",
                        styles.title,
                        {
                            short: title.length < 40,
                        },
                    ])}
                >
                    {hasVoted ? (
                        <FormattedMessage id={"globalText.thanksForVoting"} />
                    ) : (
                        pollTitle
                    )}
                </h1>

                <div
                    className={classNames([
                        "options",
                        styles.options,
                        `options-${options.length}`,
                        {
                            "show-results": showResults,
                        },
                    ])}
                >
                    {validOptions.map((option, idx) => {
                        const results = showResults
                            ? `${Math.round((option.result ?? 1) * 100)}%`
                            : "";

                        const isLastItem = idx === validOptions.length - 1;

                        const optionContent = (
                            <div
                                className={classNames([
                                    "option",
                                    styles.option,
                                    {
                                        voted: userVote === option.id,
                                        [styles.voted]: userVote === option.id,
                                        unvoted:
                                            hasVoted && userVote !== option.id,
                                        selected: selected === option.id,
                                        [styles.selected]:
                                            selected === option.id,
                                        unselected:
                                            canVote &&
                                            !hasVoted &&
                                            selected !== option.id,
                                        [styles.unselected]:
                                            canVote &&
                                            !hasVoted &&
                                            selected !== option.id,

                                        leading: option.isLeading,
                                        losing:
                                            !option.isLeading && showResults,
                                    },
                                ])}
                                onClick={() =>
                                    hasVoted || !canVote
                                        ? setSelected(null)
                                        : selected && selected === option.id
                                        ? setSelected(null)
                                        : setSelected(option.id)
                                }
                            >
                                <div className="image-wrapper">
                                    <Image sources={option.image} />

                                    <div
                                        className={classNames(
                                            "image-wrapper-results",
                                            styles["image-wrapper-results"]
                                        )}
                                    >
                                        {results}
                                    </div>
                                </div>

                                <div
                                    className={classNames([
                                        "option-title-results",
                                        styles.optionTitleResults,
                                    ])}
                                >
                                    {option.title && (
                                        <span
                                            className={classNames([
                                                styles.optionTitle,
                                                "option-title",
                                            ])}
                                        >
                                            {option.title}
                                        </span>
                                    )}

                                    <span
                                        className={classNames([
                                            "option-results",
                                            styles.optionResults,
                                            {
                                                "no-title": !option.title,
                                                [styles.noTitle]: !option.title,
                                                show: showResults,
                                                [styles.show]: showResults,
                                            },
                                        ])}
                                    >
                                        {results}
                                    </span>
                                </div>
                            </div>
                        );

                        return (
                            <React.Fragment key={option.id}>
                                <Pressable
                                    animationSize="large"
                                    disabled={!canVote}
                                >
                                    {optionContent}
                                </Pressable>

                                {!isLastItem && (
                                    <div
                                        className={classNames([
                                            styles.break,
                                            "break",
                                        ])}
                                    ></div>
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>

                {isError && (
                    <div className={classNames(["error", styles.error])}>
                        <FormattedMessage id="globalText.langVoteTryAgainLabel" />
                    </div>
                )}

                <div
                    className={classNames(
                        "modal-action",
                        styles.buttonContainer,
                        {
                            "vote-button": showVoteButton,
                            "close-button": showCloseButton,
                            disabled: isVoting,
                        }
                    )}
                >
                    <div
                        className={classNames(styles.voteButton, {
                            show: showVoteButton,
                        })}
                    >
                        <Button onClick={onVote} disabled={isVoting}>
                            <span className="buttonTextWrapper">
                                <FormattedMessage
                                    id={"globalText.langVoteSubmitLabel"}
                                />
                            </span>
                        </Button>
                    </div>

                    <div
                        className={classNames(styles.closeButton, {
                            show: showCloseButton,
                        })}
                    >
                        <Button onClick={onClose}>
                            <span className="buttonTextWrapper">
                                <FormattedMessage id={"globalText.close"} />
                            </span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(contents, modalRoot);
};
