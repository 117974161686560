import { isObject } from "./is-object";

const falsyToEmptyString = (value: unknown) =>
    value || value === 0 ? value : "";

const copyArray = <T,>(value: T[]): T[] | T =>
    Array.isArray(value) ? value.map((element) => element) : value;

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const copyObject = (object: { [key: string]: any }): any =>
    Object.entries(object).reduce(
        (values, [key, value]) => ({
            ...values,
            [key]: isObject(value)
                ? copyObject(value)
                : falsyToEmptyString(copyArray(value)),
        }),
        {}
    );
