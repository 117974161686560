import React from "react";

import { TimeUnit } from "./time-unit";

import styles from "./styles.modules.scss";

export const TimerView = ({ hours, label, minutes }) => (
    <div className={`timer ${styles.timer}`}>
        {label && <div className={`label ${styles.label}`}>{label}</div>}

        <div className={`counter ${styles.counter}`}>
            <div
                className={`hours ${styles.hours}`}
                data-total={hours.total}
                data-value={hours.value}
            >
                <TimeUnit timeUnit={hours} />
            </div>

            <div
                className={`minutes ${styles.minutes}`}
                data-total={minutes.total}
                data-value={minutes.value}
            >
                <TimeUnit timeUnit={minutes} />
            </div>
        </div>
    </div>
);
