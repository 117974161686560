import { withGameContextProvider } from "../../hocs/with-game-context-provider";
import { withLocalizationContextProvider } from "../../hocs/with-localization-context-provider";
import { withNoBackButtonOnHome } from "../../hocs/with-no-back-button-on-home";

import { withCommanderContextProvider } from "./with-commander-context-provider";
import { withRoutes } from "./with-routes";
import { withLocalizedNamespaceContextProvider } from "./with-localized-namespace-context-provider";
import { withApiContextProvider } from "./with-api-context-provider";

import { InboxController } from "./controller";
import { withPollContextProvider } from "../../stores/poll";
import { withSessionContextProvider } from "../../stores/session";
import { withReferenceWidthProvider } from "../../hocs/with-reference-width";

export const InboxContainer = withLocalizationContextProvider(
    withRoutes(
        withGameContextProvider(
            withCommanderContextProvider(
                withLocalizedNamespaceContextProvider(
                    withApiContextProvider(
                        withNoBackButtonOnHome(
                            withSessionContextProvider(
                                withPollContextProvider(
                                    withReferenceWidthProvider(InboxController)
                                )
                            )
                        )
                    )
                )
            )
        )
    )
);
