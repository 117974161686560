import { memo } from "react";

export const withMemoization = (Component) =>
    memo(Component, (current, next) => {
        const isSameId = current.id === next.id;
        const isSameFonts = current.fonts === next.fonts;
        const isSameLang = current.lang === next.lang;
        const gameDataLoaded = !!current.data === !!next.data;

        return isSameId && isSameFonts && isSameLang && gameDataLoaded;
    });
