import React from "react";

import { Provider } from "../contexts/localization";

type WithLocalizationContextProviderProps = {
    localization: LocalizationConfig;
};

export const withLocalizationContextProvider =
    (Component) => (props: WithLocalizationContextProviderProps) => {
        const { localization } = props;

        return (
            <Provider value={localization}>
                <Component {...props} />
            </Provider>
        );
    };
