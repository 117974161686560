export const formatTime = (timestamp: number): string => {
    const minutes = Math.floor((timestamp / 60) % 60);
    const hours = Math.floor((timestamp / (60 * 60)) % 24);
    const days = Math.floor(timestamp / (60 * 60 * 24));

    if (minutes === 0 && hours === 0 && days === 0) {
        return "1M";
    }

    return ` ${days <= 0 ? "" : `${days} D : `} ${
        hours <= 0 ? "0H" : `${hours} H`
    } ${days <= 0 ? `: ${minutes} M` : ""}`;
};
