import React from "react";
import { useIntl } from "react-intl";

import { OPEN_LINK } from "../../../../constants/types";
import { useSendCommand } from "../../../../hooks/use-send-command";

import { BlockVideoView } from "./view";

const getCaption = (
    author?: string,
    title?: string,
    authorThumbnail?: string
) => {
    if (!author && !title) {
        return;
    }

    return {
        author,
        title,
        icon: authorThumbnail,
    };
};

export const BlockVideoController = (props: InboxArticleVideoBlock) => {
    const sendCommand = useSendCommand();

    const { locale: lang } = useIntl();

    const { author, embed, embedVideo, title, authorThumbnail } = props;
    const { url } = embed;

    const onClick = () => !embedVideo && sendCommand(OPEN_LINK, url);

    return (
        <BlockVideoView
            {...props}
            caption={getCaption(author, title, authorThumbnail)}
            lang={lang}
            onClick={onClick}
        />
    );
};
