import React from "react";

import { Html } from "../../../html";

import styles from "./styles.modules.scss";

export const BlockHtmlView = ({ title, body }: InboxArticleTextBlock) => (
    <div className={`block-html ${styles.blockHtml}`}>
        {title && <div className={`title ${styles.title}`}>{title}</div>}

        <Html html={body} />
    </div>
);
