export const queueCommander = (sendCommand) => {
    const commands = [];

    setInterval(() => {
        if (!commands.length) return;

        const { type, payload } = commands.shift();

        sendCommand(type, payload);
    }, 250);

    return (type, payload) => {
        commands.push({ type, payload });
    };
};
