import React, { useContext } from "react";
import { useLocation, useParams } from "react-router-dom";

import { TRACK_EVENT } from "supercell-frontend-base/src/constants/types";
import { useSendCommand } from "supercell-frontend-base/src/hooks/use-send-command";

import { getProps } from "./get-props";

import { EsportArticleView } from "./view";
import { EsportContext } from "../store";

type Params = { id: string; variant?: string; slug?: string };

export const EsportArticleController = () => {
    const { state } = useContext(EsportContext);
    const { selectedRegions } = state;

    const { pathname: pagePath } = useLocation();
    const { id, variant, slug } = useParams<Params>();
    const sendCommand = useSendCommand();
    const isolated =
        (variant && variant.toLowerCase() === "isolated") ||
        (slug && slug.toLowerCase() === "isolated");

    const track = (article) => {
        const { id: pageId, title: pageTitle } = article;

        sendCommand(TRACK_EVENT, {
            event: "Pageview",
            pageId,
            pagePath,
            pageTitle,
        });
    };

    const props = {
        getProps: getProps(id, isolated, selectedRegions),
        id,
        track,
    };

    return <EsportArticleView {...props} />;
};
