import { GameParams } from "supercell-frontend-base/src/validation/game-params";
import { z } from "zod";

export const BrawlStarsGameParams = GameParams.and(
    z.object({
        maintenance: z.string().optional(),
    })
);

export type BrawlStarsGameParams = z.infer<typeof BrawlStarsGameParams>;
