import React from "react";

const DEFAULT_LANDSCAPE_WIDTH = 1180;
const DEFAULT_PORTRAIT_WIDTH = 412;

const ReferenceWidthContext = React.createContext<number>(-1);

const getReferenceWidth = (referenceWidth?: number) =>
    referenceWidth
        ? referenceWidth
        : window.innerWidth > window.innerHeight
        ? DEFAULT_LANDSCAPE_WIDTH
        : DEFAULT_PORTRAIT_WIDTH;

export const useReferenceWidth = () => React.useContext(ReferenceWidthContext);

export const withReferenceWidthProvider =
    (Component: typeof React.Component) => (props: Record<string, unknown>) => {
        return (
            <ReferenceWidthContext.Provider
                value={getReferenceWidth(
                    props.referenceWidth as number | undefined
                )}
            >
                <Component {...props} />
            </ReferenceWidthContext.Provider>
        );
    };
