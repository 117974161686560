import { copyObject } from "../utils/copy-object";

import { parseArticle } from "./articles";

export const communityParser = (data: any) => {
    data = copyObject(data);

    const { entries, globals } = data as { entries: any; globals: Globals };
    const articles: InboxArticle[] = (entries.communityEntries || []).map(
        (article: any) => parseArticle(article)
    );

    return { globals, articles };
};
