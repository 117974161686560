import React from "react";

import { ContentCardCaption } from "../../../content-card/caption";
import { ContentCard } from "../../../content-card";

import { Image } from "../../../image";

import styles from "./styles.modules.scss";

type BlockImageViewType = {
    caption?: {
        author?: string;
        title?: string;
    };
    id: string | number;
    lang: string;
    image?: ImageSourceSets;
    onClick: () => void;
};

export const BlockImageView = ({
    caption,
    id,
    image,
    lang,
    onClick,
}: BlockImageViewType) => (
    <div className={`block-image ${styles.blockImage}`}>
        <div className={`card ${styles.card}`}>
            <ContentCard id={id} lang={lang}>
                <div className={`content ${styles.content}`}>
                    {image && (
                        <div
                            className={`image ${styles.image}`}
                            onClick={onClick}
                        >
                            <Image sources={image} />
                        </div>
                    )}

                    {caption && (
                        <div className={`caption ${styles.caption}`}>
                            <ContentCardCaption
                                creator={caption.author}
                                cardId={id}
                                ctaHidden={true}
                                title={caption.title}
                            />
                        </div>
                    )}
                </div>
            </ContentCard>
        </div>
    </div>
);
